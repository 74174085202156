import { baseAppConfig, environment, isProduction } from "core/modules/config/appConfig"
import { LogLevel } from "core/modules/logging/BaseLogger"

type LoggerType = "airbrake" | "console"

interface LoggerConfig {
  name: string
  logLevel: LogLevel
  type: LoggerType
}

interface AirbrakeLoggerConfig extends LoggerConfig {
  type: "airbrake"
  projectId: number
  projectKey: string
  environment: string
  version: string
  keysBlocklist?: string[]
  maxEntries?: number
}

export const airbrakeLoggerConfig: AirbrakeLoggerConfig = {
  name: "root",
  type: "airbrake",
  logLevel: LogLevel.Error,
  projectId: baseAppConfig.airbrake_project_id,
  projectKey: baseAppConfig.airbrake_project_key,
  environment,
  version: baseAppConfig.version,
  keysBlocklist: ["access_token", "client_id", "email", "magic_token", "token"]
}

interface ConsoleLoggerConfig extends LoggerConfig {
  type: "console"
}

export const consoleLoggerConfig: ConsoleLoggerConfig = {
  name: "root",
  type: "console",
  logLevel: LogLevel.Trace
}

// Use Airbrake in production and on non-local environments only, otherwise use console
export const loggerType: LoggerType =
  isProduction && !["localhost", "127.0.0.1"].includes(location.hostname) ? "airbrake" : "console"
