import { ComponentInterface } from "core/components/base/BaseComponent"
import { Module, ModuleInternal } from "core/controller/Module"

// tslint:disable:no-console

export class ModuleManager {
  totalModules: number = 0
  totalComponentsCreated: number = 0

  // Instances of modules created by factory type modules.
  private moduleInstances: { [name: string]: ModuleInternal[] } = {}
  private modules: { [name: string]: ModuleInternal[] } = {}
  private components: { [name: string]: ComponentInterface } = {}
  // Todo: get from some common location
  private debug: boolean = import.meta.env.DEV

  /**
   * Register a module. Other modules can depend on this module using the module's name.
   */
  registerModule(module: ModuleInternal, name?: string) {
    if (!name) {
      name = module.moduleName

      // For non-minimized builds check the validity of module name
      if (this.debug) {
        const actualName = module.constructor.name
        const reportedName = name + "Module"

        if (!actualName.startsWith(reportedName))
          throw new Error(
            `Failed to register module. Actual and reported names mismatch: "${actualName}", "${reportedName}"`
          )
      }
    }

    if (!name) throw new Error("Module name empty: " + module.constructor.name)

    const moduleName = name
    if (this.modules[moduleName]) {
      this.modules[moduleName].push(module)

      this.modules[moduleName].sort((a: ModuleInternal, b: ModuleInternal) => {
        return a.dependencyPriority - b.dependencyPriority
      })
    } else {
      this.modules[moduleName] = [module]
    }

    this.totalModules++
  }

  /**
   * Register a component. Components can depend on modules but other components cannot depend on them nor can the modules depend on components.
   * @param component Module to register
   */
  registerComponent(component: ComponentInterface) {
    const componentName = `${component.constructor.name}_${this.totalComponentsCreated}`

    if (this.debug) {
      // console.groupCollapsed(`Registering component with name: "${componentName}" constructor: "${component.constructor.name}"`)
    }

    try {
      if (this.components[componentName]) throw new Error(`Two components with same name: "${componentName}"`)
      else this.components[componentName] = component

      // Components are registered after modules have been registered and resolved and dependencies will be resolved
      // at the same time when registering. This is ok because modules cannot depend on components and
      // and components cannot depend on each other
      this.resolveComponentDependencies(componentName)

      this.totalComponentsCreated++
    } finally {
      // if (this.debug) console.groupEnd()
    }

    return componentName
  }

  /**
   * Unregister a component. Components can depend on modules but other components cannot depend on them nor can the modules depend on components.
   */
  unregisterComponent(name: string) {
    if (this.components[name]) {
      // if (this.debug) {
      //   console.log(`Unregistering component "${name}"`)
      // }

      delete this.components[name]
    } else {
      // if (this.debug) {
      //   console.log(`Cannot unregister component "${name}", it was not found`)
      // }
    }
  }

  setup() {
    // Todo: only log for debug builds.
    // We don't have logger setup yet so use direct console logging
    this.resolveModuleDependencies()
    this.setupModules()
  }

  getModule<T extends Module>(name: string) {
    return <T>this.getModules<T>(name)[0]
  }

  getModules<T extends Module>(name: string) {
    if (!this.modules[name]) throw new Error(`Module not found: ${name}`)

    return <T[]>(<Module[]>this.modules[name])
  }

  /**
   * Set up all registered modules in the order specified by their
   * setupPriority
   */
  private setupModules() {
    const modules: ModuleInternal[] = []
    for (const moduleName in this.modules) {
      for (const module of this.modules[moduleName]) {
        // Don't setup factories, just the instances created by factories
        if (!module.isFactory) modules.push(module)
      }
    }

    // Get instances created by modules
    for (const moduleName in this.moduleInstances) {
      for (const module of this.moduleInstances[moduleName]) {
        modules.push(module)
      }
    }

    modules.sort((a: ModuleInternal, b: ModuleInternal): number => {
      return b.setupPriority - a.setupPriority
    })

    for (const module of modules) {
      try {
        console.log(`Setting up module: ${module.moduleName}, priority: ${module.setupPriority}`)
        module.setup()
      } catch (e) {
        if (this.debug) console.error("Failed to setup module " + module.moduleName + ", error: " + e)
        throw e
      }
    }
  }

  private resolveModuleDependencies() {
    if (this.debug) console.groupCollapsed("Resolving module dependencies")

    this.resolveSingletonDependencies()
    this.resolveInstanceDependencies()

    if (this.debug) console.groupEnd()
  }

  private resolveSingletonDependencies() {
    if (this.debug) console.groupCollapsed("Resolving singleton dependencies")
    for (const moduleName in this.modules) {
      for (const module of this.modules[moduleName]) {
        const resolvedDependencies: Record<string, true> = {}
        const dependencyNames = module.dependencies.concat(module.defaultDependencies)

        for (const dependencyName of dependencyNames) {
          if (this.debug) console.log(`Resolve module dependency: ${dependencyName}, Module: "${module.moduleName}"`)

          if (!resolvedDependencies[dependencyName]) {
            this.resolveModuleDependency(module, dependencyName)
            resolvedDependencies[dependencyName] = true
          }
        }
      }
    }
    if (this.debug) console.groupEnd()
  }

  private resolveInstanceDependencies() {
    if (this.debug) console.groupCollapsed("Resolving instance dependencies")
    for (const moduleName in this.modules) {
      if (this.moduleInstances[moduleName]) {
        for (const module of this.moduleInstances[moduleName]) {
          const resolvedDependencies: Record<string, boolean> = {}
          const dependencyNames = module.dependencies.concat(module.defaultDependencies)

          for (const dependencyName of dependencyNames) {
            if (this.debug) console.log(`Resolve module dependency: ${dependencyName}, Module: "${module.moduleName}"`)

            if (!resolvedDependencies[dependencyName]) {
              this.resolveModuleDependency(module, dependencyName)
              resolvedDependencies[dependencyName] = true
            }
          }
        }
      }
    }

    if (this.debug) console.groupEnd()
  }

  private resolveComponentDependencies(componentName: string) {
    const component = this.components[componentName]
    const resolvedDependencies = {}

    const dependencyNames = component.dependencies.concat(component.defaultDependencies)
    for (const dependencyName of dependencyNames) {
      // if (this.debug) console.log(`Resolve component dependency: ${dependencyName}, Component: "${componentName}"`)

      if (!resolvedDependencies[dependencyName]) {
        this.resolveModuleDependency(component, dependencyName, true)
        resolvedDependencies[dependencyName] = true
      }
    }
  }

  private resolveModuleDependency(module: Module, dependencyName: string, setupDependencyInstantly: boolean = false) {
    const dependencyNameCamelCase = dependencyName[0].toLowerCase() + dependencyName.substring(1)
    const dependencyNamePlural = dependencyNameCamelCase + "s"
    const dependenciesSource = this.modules[dependencyName]

    if (!dependenciesSource?.length) {
      throw new Error(`Dependency "${dependencyName}" not found for module "${module.constructor.name}"`)
    }

    const dependencies: Module[] = []

    // Make new instances of factory type dependencies
    for (const sourceDependency of dependenciesSource) {
      if ((<ModuleInternal>sourceDependency).isFactory) {
        const newInstance = (<ModuleInternal>sourceDependency).createInstance(module)
        dependencies.push(newInstance)

        if (!this.moduleInstances[dependencyName]) this.moduleInstances[dependencyName] = []

        this.moduleInstances[dependencyName].push(<ModuleInternal>newInstance)

        // If dependency is created later it should be setup right away. This is the case
        // with components
        if (setupDependencyInstantly) {
          const dependencyNames = (<ModuleInternal>newInstance).dependencies.concat(
            (<ModuleInternal>newInstance).defaultDependencies
          )

          for (const newInstanceDependencyName of dependencyNames) {
            if (this.debug) {
              console.log("Getting dependency for factory created module: " + newInstanceDependencyName)
            }
            this.resolveModuleDependency(newInstance, newInstanceDependencyName)
          }

          if (this.debug) {
            console.log("Setting up factory created dependency module instantly: " + newInstance.moduleName)
          }

          try {
            ;(<ModuleInternal>newInstance).setup()
          } catch (e) {
            // if (this.debug) console.error(`Failed to setup module ${newInstance.moduleName} error: ${e}`)
            throw e
          }
        }
      } else {
        dependencies.push(sourceDependency)
      }
    }

    if (module[dependencyNameCamelCase] !== undefined)
      throw new Error(
        `Attempted to set dependency but module already contains a member with the same name: ${dependencyNameCamelCase}, module: ${
          module.moduleName ?? module.componentName
        }`
      )

    if (module[dependencyNamePlural] !== undefined)
      throw new Error(
        `Attempted to set plural version of dependency but module already contains a member with the same name${dependencyNamePlural}, ${
          module.moduleName ?? module.componentName
        }`
      )

    module[dependencyNameCamelCase] = dependencies[0]
    module[dependencyNamePlural] = dependencies
  }
}
