import { BaseModuleWithAppName } from "../../controller/Module"
import moment from "moment"

export interface Progress {
  id?: string
  name?: string
  scope?: string
  startTime?: moment.Moment
  progress?: number
  totalProgress?: number
  canceled?: boolean
  completed?: boolean
  failed?: boolean
  details?: any
  error?: any
  result?: any
  status?: string
  updated_at?: Date
  task_id?: string
  onResolve?: (value?: Progress | PromiseLike<Progress>) => void
  onReject?: (progress: Progress) => void
  onUpdate?: (progress: Progress) => void
}

export interface ProgressManager {
  startProgress(progress: Progress): Progress
  completeProgress(progress: Progress | number)
  cancelProgress(progress: Progress)
  updateProgress(progress: Progress)
  failProgress(progress: Progress, error: any)

  findProgressById(id: string): Progress
  findProgressByName(name: string): Progress[]
  findProgressByScope(scope: string): Progress[]
}

export class ProgressManagerModule extends BaseModuleWithAppName implements ProgressManager {
  private inProgress: { [id: string]: Progress } = {}
  private done: { [id: string]: Progress } = {}

  private uniqueProgressId = 0

  get moduleName() {
    return "ProgressManager"
  }

  startProgress(progress: Progress) {
    progress.id = (++this.uniqueProgressId).toString()

    if (this.done[progress.id]) {
      this.logger.warning("Tried to start progress but progress with the same already exists", progress)
      return
    }

    if (this.inProgress[progress.id]) {
      this.logger.warning("Tried to start progress but progress with the same already exists", progress)
      return
    }

    this.logger.debug("Starting progress", { id: progress.id, progress })
    this.inProgress[progress.id] = progress

    return progress
  }

  completeProgress(progress: Progress) {
    if (!progress) return

    const id: string = (<Progress>progress).id || progress.toString()

    if (this.done[id]) {
      this.logger.warning("Tried to complete done progress", progress)
      return
    }

    const progressObject = this.inProgress[id]

    if (!progressObject) {
      this.logger.warning("Tried to complete progress that doesn't exist", progress)
      return
    }

    this.logger.debug("Completing progress", id)
    this.inProgress[id].completed = true
    this.done[id] = this.inProgress[id]
    this.inProgress[id] = undefined

    progressObject.completed = true
    if (progressObject.onResolve) progressObject.onResolve(progressObject)
  }

  updateProgress(progress: Progress) {
    if (!progress) return

    if (this.done[progress.id]) {
      this.logger.warning("Tried to update done progress", progress)
      return
    }

    if (!this.inProgress[progress.id]) {
      this.logger.warning("Tried to update progrss that doesn't exist", progress)
      return
    }

    this.logger.debug("Updating progress", { id: progress.id, progress })
    this.inProgress[progress.id] = Object.assign({}, progress)
  }

  cancelProgress(progress: Progress) {
    if (!progress) return

    const id = progress.id

    if (this.done[id]) {
      this.logger.warning("Tried to cancel done progress", progress)
      return
    }

    const progressObject = this.inProgress[id]

    if (!progressObject) {
      this.logger.warning("Tried to cancel progress that doesn't exist", progress)
      return
    }

    this.logger.debug("Canceling progress", id)
    this.inProgress[id].canceled = true
    this.done[id] = this.inProgress[id]
    this.inProgress[id] = undefined

    if (progressObject.onReject) progressObject.onReject(progressObject)
  }

  failProgress(progress: Progress, error?: any) {
    if (!progress) return
    const id = progress.id

    if (this.done[id]) {
      this.logger.warning("Tried to fail done progress", progress)
      return
    }

    const progressObject = this.inProgress[id]

    if (!progressObject) {
      this.logger.warning("Tried to fail progress that doesn't exist", progress)
      return
    }

    this.logger.debug("Failing progress", id)
    this.inProgress[id].failed = true
    this.inProgress[id].error = error || this.inProgress[id].error
    this.done[id] = this.inProgress[id]
    this.inProgress[id] = undefined

    if (progressObject.onReject) {
      progressObject.onReject(progressObject)
    }
  }

  findProgressById(id: string, includeDone?: boolean) {
    let ret = this.inProgress[id]

    if (!ret && includeDone) ret = this.done[id]

    return ret
  }

  findProgressByName(name: string, includeDone?: boolean) {
    const ret: Progress[] = []

    for (const key of Object.keys(this.inProgress)) {
      const progress: Progress = this.inProgress[key]
      if (progress.name === name) ret.push(progress)
    }

    if (includeDone) {
      for (const key of Object.keys(this.done)) {
        const progress: Progress = this.done[key]
        ret.push(progress)
      }
    }

    return ret
  }

  findProgressByScope(scope: string, includeDone?: boolean) {
    const ret: Progress[] = []

    for (const key of Object.keys(this.inProgress)) {
      const progress: Progress = this.inProgress[key]
      if (progress.scope === scope) ret.push(progress)
    }

    if (includeDone) {
      for (const key of Object.keys(this.done)) {
        const progress: Progress = this.done[key]
        ret.push(progress)
      }
    }

    return ret
  }
}
