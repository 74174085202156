import { type UnknownAction, configureStore } from "@reduxjs/toolkit"
import type { Model } from "core/modules/state/model/Model"
import type { Store as ReduxStore } from "redux"

import { BaseModuleWithAppName, type Module } from "core/controller/Module"
import type { Reducer } from "core/modules/state/reducers/CoreReducer"

export interface Store extends Module {
  dispatch(action: UnknownAction): void
  getReduxStore(): ReduxStore<Model>
}

export class StoreModule extends BaseModuleWithAppName implements Store {
  declare reducers: Reducer[]

  protected static reducers: Reducer[]
  protected store!: ReduxStore<Model>

  get moduleName() {
    return "Store"
  }

  get dependencies() {
    return ["Reducer"]
  }

  get setupPriority(): number {
    return -1000
  }

  dispatch(action: UnknownAction) {
    this.store.dispatch(action)
  }

  getReduxStore() {
    return this.store
  }

  setup() {
    this.logger.debug("Setup store")

    StoreModule.reducers = this.reducers

    this.store = configureStore<Model>({
      middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
          // @TODO: We have state mutation in dispatches, e.g. CoreActions.getView, so disable the immutable check
          immutableCheck: false,
          // @TODO: We have non-serializable data in our store, e.g. Moment instances, so disable the serializable check
          serializableCheck: false
        }),
      reducer: this.reduce
    })
  }

  protected reduce(state: Model | undefined, action: UnknownAction): Model {
    for (const reducerModule of StoreModule.reducers) {
      state = reducerModule.getReducer().call(this, state, action)
    }

    return state as Model
  }
}
