import type { AccessToken } from "lib/authentication/modules/state/model/Model"
import type { RequireKeys } from "lib/utils/TypeUtils"
import URIStatic from "urijs"

import { BaseModuleWithAppName } from "core/controller/Module"
import { baseApiConfig } from "core/modules/config/apiConfig"
import {
  ApiDescription,
  ApiMethodDescription,
  ConversionMapModule
} from "core/modules/state/conversionmap/ConversionMap"

export interface RestApiCallParameters {
  acceptLanguage?: string
  access_token?: string
  apiCallType: string
  body?: ArrayBuffer | Record<string, unknown>
  contentType?: string
  httpMethod?: string
  skipLocalUpdates?: boolean
  type: string
  url?: string | URIStatic
  [key: string]: unknown
}

export interface CoreApi {
  accessToken?: AccessToken
  errorStatusOnly?: boolean

  // todo: implement proper event handling logic
  onFailedRestApiCall(response: Response): void
  restApiCall(parameters: RestApiCallParameters): Promise<Response>
  externalRestApiCall(parameters: RequireKeys<RestApiCallParameters, "contentType" | "httpMethod">): Promise<Response>
}

export interface ApiCall {
  acceptLanguage?: string
  accessToken?: string
  body?: ArrayBuffer | Record<string, unknown>
  contentType: string
  httpMethod: string
  url: string | URIStatic // This should be typed correctly and urijs should be replaced
}

export class CoreApiModule extends BaseModuleWithAppName implements CoreApi {
  declare conversionMap: ConversionMapModule

  protected accessTokenScope?: string = "survey_api"
  protected apiConfig = baseApiConfig

  get moduleName() {
    return "CoreApi"
  }

  get dependencies() {
    return ["ConversionMap"]
  }

  onFailedRestApiCall(_response: Response) {}

  get accessToken(): AccessToken | undefined {
    if (!this.accessTokenScope) return

    const accessTokens: AccessToken[] = this.coreActions.getAllDocumentsLocal<AccessToken>("AccessToken")

    return accessTokens.find(token => token.scope === this.accessTokenScope)
  }

  set scope(scope: string) {
    this.accessTokenScope = scope
  }

  get baseUrl(): URIStatic {
    return URIStatic(this.apiConfig.apiRoot)
  }

  get baseSegments(): string[] {
    return this.apiConfig.apiRelativePath
  }

  get client() {
    return {
      client_id: this.apiConfig.clientId,
      client_secret: this.apiConfig.clientSecret
    }
  }

  async restApiCall(parameters: RestApiCallParameters) {
    this.logger.debug("Calling api", parameters)

    const apiCall = this.getApiCallFromParameters(parameters)

    const response = await fetch(apiCall.url.toString(), {
      method: apiCall.httpMethod,
      body: this.getBodyData(parameters, apiCall),
      headers: this.getHeaders(apiCall)
    })
    if (!response.ok) this.onFailedRestApiCall(response)

    return response
  }

  async externalRestApiCall(parameters: RequireKeys<RestApiCallParameters, "contentType" | "httpMethod" | "url">) {
    this.logger.debug("Calling api", parameters)

    const { body, contentType, httpMethod, url } = parameters
    const apiCall: ApiCall = { body, contentType, httpMethod, url }

    const response = await fetch(apiCall.url.toString(), {
      method: apiCall.httpMethod,
      body: this.getBodyData(parameters, apiCall),
      headers: this.getHeaders(parameters)
    })
    if (!response.ok) this.onFailedRestApiCall(response)

    return response
  }

  protected getBodyData(
    parameters: Pick<RestApiCallParameters, "body">,
    apiCall: ApiCall
  ): string | ArrayBuffer | FormData | undefined {
    if (apiCall.httpMethod.toUpperCase() === "GET") return

    parameters = parameters || {}

    // Check if we don't have "regular" body, and we should use one from method description
    if (apiCall.body && !parameters.body) {
      parameters.body = apiCall.body
    }

    if (parameters.body === undefined) return undefined

    // Todo: This isn't quite right and body type should be specified independently of content type
    if (apiCall.contentType === "application/json" || apiCall.contentType === "text") {
      return JSON.stringify(parameters.body)
    }
    // Excel export needs to have an arrayBuffer (raw)
    else if (apiCall.contentType === "arrayBuffer") {
      return parameters.body as ArrayBuffer
    } else {
      const formData = new FormData()

      for (const key of Object.keys(parameters.body)) {
        formData.append(key, parameters.body[key])
      }

      return formData
    }
  }

  getHeaders(parameters: Partial<Record<"accessToken" | "acceptLanguage" | "contentType", string>>): HeadersInit {
    const headers: HeadersInit = {}

    if (parameters.accessToken) headers["Authorization"] = "Bearer " + parameters.accessToken
    if (parameters.acceptLanguage) headers["Accept-Language"] = parameters.acceptLanguage
    if (parameters.contentType) headers["Content-Type"] = parameters.contentType

    return headers
  }

  protected getApiCallFromParameters(parameters: RestApiCallParameters): ApiCall {
    let url = this.baseUrl
    let body = parameters.body
    const acceptLanguage = parameters.acceptLanguage
    const accessToken = parameters.access_token
    const collectionName = parameters.type
    const callType = parameters.apiCallType

    const methodDescription = this.getApiDescription(collectionName)[callType]

    if (!methodDescription)
      this.logger.errorAndThrow(`Method description missing: ${collectionName} ${callType}`, {
        collectionName,
        callType
      })

    url = url.segment(this.getSegments(methodDescription, parameters))
    url = url.setSearch(this.getSearch(methodDescription, parameters))

    let contentType = parameters.contentType || methodDescription.contentType || "application/json"

    // Multipart form data is default on should not be set
    if (contentType === "multipart/form-data") contentType = undefined

    // Check if we have body from method description
    if (!body) {
      const bodyFromMethod = this.getBodyFromMethod(methodDescription, parameters)
      if (Object.keys(bodyFromMethod).length > 0) {
        body = bodyFromMethod
      }
    }

    const httpMethod =
      parameters.httpMethod || methodDescription.method || this.getDefaultHttpMethodFromApiCallMethod(callType)
    return { accessToken, acceptLanguage, body, contentType, httpMethod, url }
  }

  protected getSegments(methodDescription: ApiMethodDescription, parameters: { [id: string]: string }): string[] {
    const segments: string[] = []
    for (const segment of methodDescription.segments || []) {
      if (typeof segment === "string") {
        segments.push(segment)
      } else {
        let segmentParameter = parameters[segment.name] || segment.default
        if (!segmentParameter) {
          if (segment.required !== false) {
            this.logger.errorAndThrow(`Segment parameter missing: ${segment?.name}`)
          }

          continue
        } else {
          segmentParameter = segmentParameter.toString()
        }
        segments.push(segmentParameter)
      }
    }

    return segments
  }

  protected getBodyFromMethod(
    methodDescription: ApiMethodDescription,
    parameters: Record<string, string>
  ): Record<string, string> {
    if (!methodDescription.body) return {}

    const body: Record<string, string> = {}
    for (const bodyParam of Object.values(methodDescription.body)) {
      const bodyItem = parameters[bodyParam.paramName || bodyParam.name]

      if (bodyItem) body[bodyParam.name] = bodyItem
    }

    return body
  }

  protected getSearch(
    methodDescription: ApiMethodDescription,
    parameters: Record<string, string>
  ): Record<string, string> {
    if (!methodDescription.search) return {}

    const search: Record<string, string> = {}
    for (const key of Object.keys(methodDescription.search)) {
      const searchParam = methodDescription.search[key]
      if (searchParam.name === "access_token") {
        continue
      }
      let searchStr = parameters[searchParam.paramName || searchParam.name]

      // Get parameter value from body if it's included there. Nestet values are not supported for now
      if (searchStr === undefined && searchParam.paramFromBody && parameters.body) {
        const paramFromBody = parameters.body[searchParam.paramFromBody]
        if (paramFromBody) searchStr = paramFromBody.toString()
      }

      if (searchStr === undefined) {
        if (searchParam.default !== undefined) searchStr = searchParam.default
        else if (searchParam.required)
          this.logger.errorAndThrow("Search parameter missing", searchParam.paramName || searchParam.name)
      }

      if (searchStr !== "") search[searchParam.name] = searchStr
    }

    search.noCache = Date.now().toString() + Math.round(Math.random() * 1000000).toString()

    return search
  }

  protected getApiDescription(type: string): ApiDescription {
    if (!this.conversionMap.map[type])
      this.logger.errorAndThrow("Could not find api description in conversionMap", type)

    const apiDescription: ApiDescription = this.conversionMap.map[type].__api

    if (!apiDescription) throw new Error(`Conversion map is missing API description: ${type}`)

    return apiDescription
  }

  protected getDefaultHttpMethodFromApiCallMethod(apiCallMethod: string): string {
    switch (apiCallMethod) {
      case "get":
        return "get"
      case "list":
        return "get"
      case "create":
        return "post"
      case "update":
        return "put"
      case "updateMany":
        return "post"
      case "remove":
        return "delete"
      default:
        return "get"
    }
  }
}
