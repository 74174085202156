import { ConversionMapCollection, ConversionMapModule } from "core/modules/state/conversionmap/ConversionMap"
import { DocumentValueConverter } from "core/modules/state/conversionmap/ValueConverters"
import { Doc } from "core/modules/state/model/Model"
import { ModelManagerInternal } from "core/modules/state/model/ModelManager"
import { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"

import { BaseModuleWithAppName } from "../../../controller/Module"

type ConversionType = "Link" | "LinkArray" | "Date" | "DateTime"

/**
 * Converts documents between API JSON and internal object format
 */
export interface DocumentConverter {
  /**
   * Import document and any related documents into the model.
   */
  importDocument(document: any, documentType: string, inline?: boolean): Doc

  importView(
    viewName: string,
    viewData: any[],
    parameters: any,
    viewDataRaw: any,
    onImportDocument: (document: Doc, previusDocument: Doc) => void
  )

  /**
   * Return document in a format understood by an external system.
   */
  exportDocument(document: Doc, documentType: string)

  exportLinkedDocument(document: Doc, documentType?: string)
}

export class DocumentConverterModule extends BaseModuleWithAppName implements DocumentConverter {
  declare conversionMap: ConversionMapModule
  declare modelManager: ModelManagerInternal

  get moduleName() {
    return "DocumentConverter"
  }

  get dependencies() {
    return ["ConversionMap", "ModelManager"]
  }

  importDocument(document: any, documentType: string, inline?: boolean, compact: boolean = false): Doc {
    this.logger.trace("Importing document", { documentType, document })

    if (!document.id) {
      const nextId = this.modelManager.getNextDocumentId()
      this.logger.debug("Importing document without id. Using generated local id", nextId)
      document.id = nextId
    } else {
      document.id = document.id.toString()
    }
    ;(<Doc>document).__compact = compact
    ;(<Doc>document).__type = documentType

    this.convertDocumentValues(document, documentType)

    if (!inline) this.storeDocument(document, documentType)

    this.logger.trace("Imported document", document)

    return <Doc>document
  }

  importView(
    viewName: string,
    viewData: any,
    parameters: any,
    viewDataRaw: any,
    onImportDocument: (document: Doc, previousDocument: Doc) => void
  ) {
    this.logger.startGroup("Import view: " + viewName, false)

    // Get array or documents or default to empty array
    const documents = (parameters.itemsKey ? viewData[parameters.itemsKey] : viewData) || []
    const conversionMapCollection = <ConversionMapCollection>this.conversionMap.map[viewName]
    const documentType = conversionMapCollection.__api.documentType

    if (!documentType)
      this.logger.error("View in conversion maps is missing document type property (documentType)", viewName)

    try {
      const viewContainer: ViewContainerManager<any> = this.modelManager.initializeView(viewName, documentType)

      viewContainer.parameters = parameters
      viewContainer.raw = viewDataRaw

      for (const document of documents) {
        if (!document) continue

        const previousDocument = this.modelManager.getDocument(document.id, documentType)

        const importedDocument: Doc = this.importDocument(document, documentType)
        viewContainer.push(importedDocument)

        onImportDocument(importedDocument, previousDocument)
      }
    } finally {
      this.logger.endGroup()
    }
  }

  exportDocument(document: any, documentType: string): any {
    const converters: { [id: string]: DocumentValueConverter } = this.conversionMap.map[documentType]

    this.logger.debug("Exporting document", { documentType, document })

    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue
        try {
          converters[key].export(<Doc>document, key, this.modelManager, this)
        } catch (error) {
          this.logger.error("Failed to export document value", { error, key, document })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from internal types to JSON.",
        documentType
      )
    }

    delete document.__type

    // Todo: configure id type and name
    if (document.id) document.id = parseInt(document.id, 10)

    this.logger.debug("Exporting document (after conversion)", document)

    return document
  }

  exportLinkedDocument(document: Doc, documentType: string): any {
    documentType = documentType || document.__type
    const converters: { [id: string]: DocumentValueConverter } = this.conversionMap.map[documentType]

    this.logger.debug("Exporting linked document", { documentType, document })

    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue
        try {
          if (converters[key].exportForLinked) converters[key].export(<Doc>document, key, this.modelManager, this)
        } catch (error) {
          this.logger.error("Failed to export document value", { error, key, document })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from internal types to JSON.",
        documentType
      )
    }

    this.logger.debug("Exporting linkeddocument (after conversion)", document)

    return document
  }

  private convertDocumentValues(document: any, documentType: string): void {
    const converters: { [id: string]: DocumentValueConverter } = this.conversionMap.map[documentType]

    if (converters) {
      for (const key of Object.keys(converters)) {
        if (key.startsWith("__")) continue

        try {
          converters[key].import(<Doc>document, key, this.modelManager, this)
        } catch (error) {
          this.logger.error("Failed to import document value", { key, document, error })
        }
      }
    } else {
      this.logger.debug(
        "No conversion maps for document. Not doing any mapping from JSON to internal types.",
        documentType
      )
    }
  }

  private storeDocument(document: Doc, documentType: string) {
    document = this.modelManager.setDocument(document)
  }
}
