import moment from "moment"

import { DocumentConverter } from "core/modules/state/documentconverter/DocumentConverter"
import { Doc, Link } from "core/modules/state/model/Model"
import { ModelManagerInternal } from "core/modules/state/model/ModelManager"

export interface DocumentValueConverter {
  // If set to false this value will not be exported for linked documents
  exportForLinked: boolean
  converterType?: string
  target?: string

  import(document: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter)

  export(document: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter)
}

export class DocumentValueConverterImpl implements DocumentValueConverter {
  get exportForLinked(): boolean {
    return true
  }

  import(document: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    throw new Error("Not implemented")
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    throw new Error("Not implemented")
  }
}

export type ConversionTypes = "none" | "inline" | "links_only" | "linked_document"
export interface LinkConverterOptions {
  typeKey?: string
  isCompactDocument?: boolean
  exportType?: ConversionTypes
  importType?: ConversionTypes
  conversion?: ConversionTypes
  idProperty?: string
}

export class LinkConverter extends DocumentValueConverterImpl {
  converterType = "LinkConverter"

  target: string
  protected typeKey: string
  protected identifierKey: string
  protected exportType: string
  protected importType: string
  protected idProperty: string

  get exportForLinked(): boolean {
    return false
  }

  constructor(target: string, options: LinkConverterOptions = <LinkConverterOptions>{}) {
    super()
    this.target = target
    this.typeKey = options.typeKey
    this.exportType = options.exportType || options.conversion
    this.importType = options.importType || options.conversion
    this.idProperty = options.idProperty
  }

  import(parentDocument: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    if (parentDocument[key] === undefined || parentDocument[key] === null) return

    let documentToImport = parentDocument[key]
    if (!documentToImport.id && this.importType !== "inline")
      throw new Error("Cannot import document without id. " + key)

    // For some documents, their type is given in one of parent document's properties.
    if (this.target === "Unknown") documentToImport.__type = parentDocument[this.typeKey]
    else documentToImport.__type = this.target

    if (this.importType === "links_only") {
      this.replaceValueWithLink(documentToImport, parentDocument, key)
    } else if (this.importType === "inline") {
      let inlineDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type, true)
      parentDocument[key] = inlineDocument
    } else {
      let linkedDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type)
      this.replaceValueWithLink(linkedDocument, parentDocument, key)
    }
  }

  export(parentDocument: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    if (!parentDocument[key] || !this.exportType) return

    if (this.exportType === "inline") {
      parentDocument[key] = documentConverter.exportLinkedDocument(parentDocument[key], parentDocument[key].__type)
    }
  }

  protected replaceValueWithLink(linkedDocument: Doc, parentDocument: Doc, key: string) {
    parentDocument[key] = <Link<Doc>>{
      __type: linkedDocument.__type,
      id: linkedDocument.id
    }
  }
}

export class LinkArrayConverter extends LinkConverter {
  converterType = "LinkArrayConverter"

  constructor(target: string, options: LinkConverterOptions = <LinkConverterOptions>{}) {
    super(target, options)
  }

  import(parentDocument: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    if (!parentDocument[key] || parentDocument[key].length === 0) return

    for (let i = 0; i < parentDocument[key].length; i++) {
      const documentToImport = parentDocument[key][i]
      if (this.idProperty) {
        documentToImport.id = documentToImport[this.idProperty]
      }

      if (!documentToImport.id && this.importType !== "inline")
        throw new Error("Cannot import document without id. " + key)

      // For some documents, their type is given in one of parent document's properties.
      if (this.target === "Unknown") documentToImport.__type = parentDocument[this.typeKey]
      else documentToImport.__type = this.target

      if (this.importType === "links_only") {
        this.replaceArrayValueWithLink(documentToImport, parentDocument, key, i)
      } else if (this.importType === "inline") {
        const inlineDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type, true)

        parentDocument[key][i] = inlineDocument
      } else {
        const linkedDocument: Doc = documentConverter.importDocument(documentToImport, documentToImport.__type)
        this.replaceArrayValueWithLink(linkedDocument, parentDocument, key, i)
      }
    }
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal, documentConverter: DocumentConverter) {
    if (this.importType === "none" || !document[key] || document[key].length === 0) return

    for (let i = 0; i < document[key].length; i++) {
      if (this.importType === "links_only") {
        document[key][i] = { id: document[key][i] }
      } else if (this.importType === "inline") {
        document[key][i] = documentConverter.exportLinkedDocument(document[key][i])
      }
    }
  }

  protected replaceArrayValueWithLink(linkedDocument: Doc, parentDocument: Doc, key: string, index: number) {
    parentDocument[key][index] = <Link<Doc>>{
      __type: linkedDocument.__type,
      id: linkedDocument.id
    }
  }
}

// Do not use. Use linked document converters and type "inline"
export class InlineDocumentConverter extends DocumentValueConverterImpl {
  target: string
  typeKey: string

  constructor(target: string, options: LinkConverterOptions = <LinkConverterOptions>{}) {
    super()
    this.target = target
    this.typeKey = options.typeKey
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    throw new Error("Not implemented")
  }
}

export class InlineDocumentArrayConverter extends DocumentValueConverterImpl {
  target: string
  typeKey: string

  constructor(target: string, options: LinkConverterOptions = <LinkConverterOptions>{}) {
    super()
    this.target = target
    this.typeKey = options.typeKey
  }

  import(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    throw new Error("Not implemented")
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    throw new Error("Not implemented")
  }
}

export class DateTimeValueConverter extends DocumentValueConverterImpl {
  import(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    const rawValue = document[key]
    if (!rawValue) {
      return
    }
    document[key] = moment.utc(rawValue)
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    if (!document[key]) return

    let value = <moment.Moment>document[key]
    document[key] = value.toJSON()
  }
}

export class DateValueConverter extends DocumentValueConverterImpl {
  private importFormat
  private exportFormat

  constructor(importFormat?, exportFormat?) {
    super()

    this.importFormat = importFormat || "YYYYMMDD"
    this.exportFormat = exportFormat || "YYYYMMDD"
  }

  import(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    let rawValue = document[key]
    if (!rawValue) {
      return
    }
    document[key] = moment.utc(document[key], this.importFormat)
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    if (document[key]) document[key] = document[key].format(this.exportFormat)
  }
}

export class UnixDateConverter extends DocumentValueConverterImpl {
  import(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    let ret
    try {
      document[key] = moment.unix(document[key])
    } catch (e) {}
  }

  export(document: Doc, key: string, modelHelper: ModelManagerInternal) {
    throw new Error("Not implemented")
  }
}

export interface DateConverter {
  import(value: string): Date
  export(value: Date): string
}

export class ApiDateConverter implements DateConverter {
  import(value: string): Date {
    // YYYY-MM-DD
    return moment.utc(value).toDate()
  }

  export(date: Date): string {
    return moment(date).format("YYYY-MM-DD")
  }
}

export class ApiDateTimeConverter implements DateConverter {
  import(value: string): Date {
    // YYYY-MM-DDTHH:mm:SS
    return moment.utc(value).toDate()
  }

  export(date: Date): string {
    return moment(date).toISOString()
  }
}
