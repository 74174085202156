import classNames from "classnames"
import * as ReactDOM from "react-dom"
import { style } from "typestyle"

import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"
import Spinner from "lib/ui/components/symbols/Spinner"

import { ViewComponent } from "../base/ViewComponent"

const defaultProgressTimeout = 1000

interface BlockDivProps extends ViewComponentProps {
  className?: string
  showProgress?: boolean
  progressTimeout?: number
  opacity?: number
  opaqueOnSpinnerOnly?: boolean
  onClick?: () => void
}

interface BlockDivState extends ViewComponentState {
  showProgress?: boolean
}

export default class BlockDiv extends ViewComponent<BlockDivProps, BlockDivState> {
  private progressTimeout

  get componentName(): string[] {
    return ["ui", "BlockDiv"]
  }

  private get blockDivClass() {
    const { opacity } = this.props

    return style({
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: 9998,
      top: 0,
      left: 0
    })
  }

  private get backgroundColorClass() {
    let opacity = this.props.opacity === undefined ? 0.3 : this.props.opacity

    if (this.props.opaqueOnSpinnerOnly && !this.state.showProgress) opacity = 0

    return style({
      backgroundColor: `rgba(0,0,0,${opacity})`
    })
  }

  private get spinnerClass(): string {
    return style({
      position: "fixed",
      width: "100vw",
      height: "100vh",
      zIndex: 9999,
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    })
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  UNSAFE_componentWillMount() {
    super.UNSAFE_componentWillMount()

    if (this.props.showProgress)
      this.progressTimeout = setTimeout(this.showProgress, this.props.progressTimeout || defaultProgressTimeout)
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout)
  }

  render() {
    super.render()
    const props = this.props

    return ReactDOM.createPortal(this.renderBlockDiv(), document.getElementById("webapp_container"))
  }

  private showProgress = () => {
    this.updateState(state => {
      state.showProgress = true
    })
  }

  private renderBlockDiv() {
    return (
      <div>
        <div
          className={classNames(this.backgroundColorClass, this.blockDivClass, this.props.className)}
          onClick={this.props.onClick}
        >
          {this.props.children}
        </div>
        {this.renderSpinner()}
      </div>
    )
  }

  private renderSpinner() {
    if (!this.props.showProgress || !this.state.showProgress) return undefined
    return (
      <div className={this.spinnerClass}>
        <Spinner />
      </div>
    )
  }
}
