import { UnixDateConverter } from "core/modules/state/conversionmap/ValueConverters"
import { BaseModule } from "../../../controller/Module"
import { ConversionMap, ConversionMapRoot } from "core/modules/state/conversionmap/ConversionMap"

export class ConversionMapModule extends BaseModule implements ConversionMap {
  get moduleName() {
    return "ConversionMapModule"
  }

  get map(): any {
    return conversionMapContents
  }
}

/**
 * Describes how objects are translated between internal JS format API JSON
 */
const conversionMapContents: ConversionMapRoot = {
  AccessToken: {
    created_at: new UnixDateConverter()
  }
}
