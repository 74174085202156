export function RethrowExceptions(target, key, descriptor) {
  const originalMethod = descriptor.value

  descriptor.value = async function (...args) {
    try {
      return await originalMethod.apply(this, args)
    } catch (error) {
      throw error
    }
  }

  return descriptor
}
