import { Doc, DocumentContainer } from "./Model"
import { ContainerManager } from "./ContainerManager"
import { ModelManagerInternal } from "./ModelManager"
import { Logger } from "core/modules/logging/Logger"

/**
 * Container class for managing documents in external DocumentContainer.
 */
export class DocumentContainerManager extends ContainerManager {
  private _container: DocumentContainer<Doc>
  get container(): DocumentContainer<Doc> {
    return this._container
  }

  constructor(container: DocumentContainer<Doc>, modelManager: ModelManagerInternal, logger: Logger) {
    super()
    this._container = container
    this.modelManager = modelManager
    this.logger = logger
  }

  get apiPathName(): string {
    this.verifyStateRead()
    return this._container.apiPath
  }

  get all() {
    this.verifyStateRead()
    const all = []

    for (const key of Object.keys(this._container.dictionary)) {
      all.push(this._container.dictionary[key])
    }

    return all
  }

  /**
   * Get object from container with key
   */
  get(key: string) {
    this.verifyStateRead()
    return this._container.dictionary[<string>key]
  }

  set(document): void {
    this.setDirtyFlag()
    this.addDocumentToDictionary(document)
  }

  removeDocument(document: Doc | string) {
    this.verifyStateModify()
    this.setDirtyFlag()

    if (typeof document === "string") this.removeItemFromDictionary(document)
    else this.removeItemFromDictionary(document.id)
  }

  initialize(type: string): void {
    this.verifyStateModify()
    this._container.__type = type
    this._container.dictionary = {}
    this._dirty = false
  }

  private removeItemFromDictionary(key: string): Doc {
    const document: Doc = this._container.dictionary[key]
    this._container.dictionary[key] = undefined
    return document
  }

  private addDocumentToDictionary(document: Doc, checkForDuplicates: boolean = false): boolean {
    const key: string = document.id
    const exists = this._container.dictionary.hasOwnProperty(key)

    if (checkForDuplicates && exists) {
      throw new Error("Duplicate key. Key: ${key}, container: ${this._container.type} ")
    }

    this._container.dictionary[key] = document

    return exists
  }

  /**
   * In case of any mutation, the unerlying data structures will be cloned and dirty flag set. We do not wish to
   * clone always due to performance cost.
   */
  private setDirtyFlag() {
    if (this._dirty) return

    this.logger.trace("Dirty flag set. Cloning document container", this.container.__type)

    this._container = this.modelManager.cloneDocumentContainer(this.container.__type)

    this.container.dictionary = Object.assign({}, this.container.dictionary)
    this._dirty = true
  }
}
