import {
  OrganisationReport,
  PointCategoriesConfig,
  Question,
  ReportBreakdown,
  ReportInstance,
  ReportInstanceOrganisation,
  Results,
  Similarity,
  SubGroupResults,
  SurveyQuestionCategory,
  Tag,
  Team
} from "app/surveys_reports/modules/state/model/Model"
import { CoreActions } from "core/modules/actions/CoreActions"
import { Localization } from "core/modules/localization/Localization"
import { Theme } from "core/modules/themes/Theme"
import { followUpIdentifyingQuestion, getQuestionCoblCategroyPrefix, ratingIndex } from "./WellbeingCommonReportUtils"

const maximumBarLabelLength = 30

export const getOptionColors = (theme: Theme) => {
  return [
    theme.colors.report.report_excellent,
    theme.colors.report.report_well_done,
    theme.colors.report.report_neutral,
    theme.colors.report.report_need_attention,
    theme.colors.report.report_need_extra_focus
  ]
}

export const getSelectedResults = (
  instance: ReportInstance | undefined,
  teamId?: string,
  tagId?: string
): Results | undefined => {
  if (teamId) {
    return tagId ? instance?.teams?.[teamId]?.tags?.[tagId] : instance?.teams?.[teamId]
  }

  if (tagId) return instance?.tags?.[tagId]

  return instance?.organisation
}

/** Combine point categories to match limits */
export const getValuesFromPointCategories = (pointCategories: number[], config: PointCategoriesConfig): number[] => {
  const { minimum, step } = config

  return pointCategories?.reduce((values: number[], value: number, index: number) => {
    const rating = ratingIndex(minimum + index * step)

    if (rating !== -1) {
      values[rating] = (values[rating] ?? 0) + value
    }

    return values
  }, [])
}

export const truncateBarGraphLabel = (label: string | string[]) => {
  const truncated = [...label]

  if (truncated[0].length >= maximumBarLabelLength) {
    truncated[0] = truncated[0].substring(0, maximumBarLabelLength - 1) + "…"
  }

  return label instanceof Array ? truncated : truncated[0]
}

export const isReportShownByCategory = (questions: Question[], minQuestionsToShowByCategory: number) => {
  if (questions.length < minQuestionsToShowByCategory) {
    return false
  }

  // Don't show by category if any question has category missing
  return !questions.find(question => !question.category?.en)
}

export const getInsufficientDataReason = (
  doc: Team | Tag,
  similarities: Similarity[] | undefined,
  teams: Team[] | undefined,
  tags: Tag[] | undefined,
  appName: string,
  localization: Localization
) => {
  if (!similarities || !teams || !tags || !doc) return ""

  let insufficientDataReason
  const similarTeams = getSimilarities(doc, "team", similarities, teams, tags)
  const similarTags = getSimilarities(doc, "tag", similarities, teams, tags)

  if (similarTeams.length > 0 && similarTags.length > 0) {
    insufficientDataReason = localization
      .txt(appName, "report", "reportTools", "too_similar_to_teams_and_tags")
      .replace("{0}", similarTeams.join(","))
      .replace("{1}", similarTags.join())
  } else if (similarTeams.length > 0) {
    insufficientDataReason = localization
      .txt(appName, "report", "reportTools", "too_similar_to_teams")
      .replace("{0}", similarTeams.join(","))
  } else if (similarTags.length > 0) {
    insufficientDataReason = localization
      .txt(appName, "report", "reportTools", "too_similar_to_tags")
      .replace("{0}", similarTags.join(","))
  }
  return insufficientDataReason
}

export const followupAggregatedReport = (questions: Question[]) => {
  return questions.some(q => q.key === followUpIdentifyingQuestion)
}

const getSimilarities = (
  source: Team | Tag,
  type: "team" | "tag",
  similarities: Similarity[],
  teams: Team[],
  tags: Tag[]
) => {
  if (!similarities || !teams || !tags || !source) return []

  return []
    .concat(
      ...similarities
        .filter(
          similarity =>
            similarity.document.type.toLowerCase() === source.__type.toLowerCase() &&
            similarity.document.id.toString() === source.id.toString()
        )
        .map(similarity => similarity.similar)
    )
    .filter(similar => similar.type === type)
    .map(similar => {
      return type === "team"
        ? teams.filter(team => team.id?.toString() === similar.id?.toString())[0]
        : tags.filter(tag => tag.id?.toString() === similar.id?.toString())[0]
    })
    .filter(similar => similar)
    .map(similar => similar.name)
}

export const processOrganisationReport = (
  organisationReport: OrganisationReport,
  reportId: string,
  coreActions: CoreActions
) => {
  if (organisationReport.processed) return organisationReport

  organisationReport = Object.assign({}, organisationReport)
  organisationReport.id = reportId

  const questions = organisationReport.questions.map(question =>
    coreActions.getDocumentLocal<Question>(question.id!, question.__type!)
  )

  processQuestionCategories(organisationReport, questions)
  processQuestionBreakdowns(organisationReport, questions)
  processTagCategories(organisationReport, coreActions)
  processCategoryBreakdowns(organisationReport, questions)

  organisationReport.processed = true

  return organisationReport
}

export const aggregatedComparisonResult = (organisationReport: OrganisationReport) => {
  // Report history is explicitly specified when starting a survey (comparison report).
  // Therefore we can assume that if there is more than one instance, it is a comparison report.
  return organisationReport.instances.length > 1 ? organisationReport.instances[1] : undefined
}

const processQuestionBreakdowns = (organisationReport: OrganisationReport, questions: Question[]) => {
  const addBreakdownAveragePoints = (breakdown: ReportBreakdown, question: Question) => {
    // Get the average points of results. Divide by 100 because answer_percentages are from 0 to 100
    breakdown.average_points =
      question.options
        .map((value, i) => breakdown.answer_percentages[i] * value.points!)
        .reduce((value, result) => result + value, 0) / 100
  }

  for (const instance of organisationReport.instances) {
    if (instance.organisation.insufficient_data) continue

    for (const questionId of Object.keys(instance.organisation.breakdown)) {
      const question = questions.find(q => {
        return q.id === questionId || q.key === questionId
      })
      if (!question) continue

      addBreakdownAveragePoints(instance.organisation.breakdown[questionId], question)

      for (const team of organisationReport.teams) {
        const instanceTeam = instance.teams[team.id!]
        if (!instanceTeam || instanceTeam.insufficient_data) continue

        const teamBreakdown = instanceTeam.breakdown[questionId]
        if (!teamBreakdown) continue

        addBreakdownAveragePoints(teamBreakdown, question)

        if (instanceTeam.tags) {
          organisationReport.filterTagsAvailable = true

          for (const key of Object.keys(instanceTeam.tags)) {
            if (instanceTeam.tags[key].insufficient_data) continue

            addBreakdownAveragePoints(instanceTeam.tags[key].breakdown[questionId], question)
          }
        }
      }

      for (const tag of organisationReport.tags) {
        const instanceTag = instance.tags[tag.id!]
        if (!instanceTag || instanceTag.insufficient_data) continue

        const tagBreakdown = instanceTag.breakdown[questionId]
        if (!tagBreakdown) continue

        addBreakdownAveragePoints(tagBreakdown, question)
      }
    }
  }
}

const processQuestionCategories = (organisationReport: OrganisationReport, questions: Question[]) => {
  const categories = Object.assign({}, organisationReport).question_categories as SurveyQuestionCategory[]

  for (const category of categories) {
    // Get category data from first non-custom question
    const categoryQuestion = questions.find(q => !q.custom && q.category_object?.id === category.id)
    const prefix = (!category.custom && getQuestionCoblCategroyPrefix(categoryQuestion)) || undefined

    category.categoryPrefix = prefix
    category.isCoblElement = !!prefix
  }

  return categories
}

const processCategoryBreakdowns = (organisationReport: OrganisationReport, questions: Question[]) => {
  if (!organisationReport.question_categories) return

  const addCategoryBreakdown = (instance: ReportInstanceOrganisation | SubGroupResults) => {
    if (!instance.breakdown) return

    instance.category_breakdown = {}

    for (const category of organisationReport.question_categories) {
      const categoryQuestions = questions.filter(question => question.category_object?.id === category.id)
      if (categoryQuestions.length === 0) continue

      const activeCategoryResults = categoryQuestions
        .map(question => {
          return instance.breakdown[question.id!] || instance.breakdown[question.key!]
        })
        .filter(Boolean)

      const average_points =
        activeCategoryResults.map(breakdown => breakdown.average_points).reduce((a, b) => a + b, 0) /
        activeCategoryResults.length

      instance.category_breakdown[category.id!] = { average_points, answer_percentages: [] }

      // Count answer percentages
      for (let i = 0; i < 5; i++) {
        instance.category_breakdown[category.id!].answer_percentages[i] =
          categoryQuestions
            .map(question => {
              return instance.breakdown[question.id!] || instance.breakdown[question.key!]
            })
            .filter(Boolean)
            .map(breakdown => breakdown.answer_percentages[i])
            .reduce((a, b) => a + b, 0) / categoryQuestions.length
      }
    }
  }

  for (const instance of organisationReport.instances) {
    if (instance.organisation.insufficient_data) continue

    addCategoryBreakdown(instance.organisation)

    for (const team of organisationReport.teams) {
      const instanceTeam = instance.teams[team.id!]
      if (!instanceTeam || instanceTeam.insufficient_data) continue

      addCategoryBreakdown(instanceTeam)

      for (const tag of Object.values(instanceTeam.tags || [])) {
        if (tag.insufficient_data) continue

        addCategoryBreakdown(tag)
      }
    }

    for (const tag of organisationReport.tags) {
      const instanceTag = instance.tags[tag.id!]
      if (!instanceTag || instanceTag.insufficient_data) continue

      addCategoryBreakdown(instanceTag)
    }
  }
}

const processTagCategories = (organisationReport: OrganisationReport, coreActions: CoreActions) => {
  const tagCategories = {}
  for (const tagLink of organisationReport.tags) {
    const tag = coreActions.getDocumentLocal<Tag>(tagLink.id, "Tag")

    if (tag.category_name) tagCategories[tag.category_name] = true
  }

  organisationReport.tagCategories = Object.keys(tagCategories)
}

// const findOriginalIndices = (organisationReport, currentInstances) => {
//   const originalIndices = []
//   const instanceIds = currentInstances.map(instance => instance.id)
//   for (let index = 0; index < organisationReport.instances.length; index++) {
//     if (instanceIds.indexOf(organisationReport.instances[index].id) !== -1) {
//       originalIndices.push(index)
//     }
//   }
//   return originalIndices
// }

// const getTitleForPrinting = (organisationReport: OrganisationReport) => {
//   const instance = organisationReport.instances[0]
//   const surveyTitle = this.localization.txt(organisationReport.survey.title)
//   const customTitle = organisationReport.title ? `${organisationReport.title}, ` : ""
//   // Used to contain business ids. Was removed due to unknown usefulness.
//   // Possible use case for multi-business-id orgs.
//   const companyTitle = ""
//   let dates = ""
//   if (instance && instance.start_time && instance.ends_on)
//     dates = `, ${instance.start_time.format(this.localization.dateFormat)}`
//   const title = `${customTitle}${surveyTitle}${companyTitle}${dates}`
//   return title
// }

// const processTagHistory = (organisationReport: OrganisationReport) => {
//   const currentInstance = organisationReport.instances[0]
//   const tagKeysWithData = Object.keys(currentInstance.tags).filter(
//     key => !currentInstance.tags[key].insufficient_data
//   )

//   for (const key of tagKeysWithData) {
//     const tagResults = organisationReport.instances
//       .map(instance => instance.tags[key] || ({} as any))
//       .filter(result => !!result)

//     if (tagResults.length > 1) this.processHistoryBreakdown(tagResults[0], tagResults[1])
//   }

//   // // Tag question breakdowns
//   // for (const key of tagKeysWithData) {
//   //   const tagBreakdownResults = organisationReport.instances
//   //     .map(instance => (instance.tags[key] || ({} as any)).breakdown)
//   //     .filter(result => !!result)

//   //   if (this.processHistoryBreakdownHashes(tagBreakdownResults)) organisationReport.tagsWithHistory[key] = true
//   // }

//   // Tag category breakdowns
//   for (const key of tagKeysWithData) {
//     const tagBreakdownResults = organisationReport.instances
//       .map(instance => (instance.tags[key] || ({} as any)).category_breakdown)
//       .filter(result => !!result)

//     if (this.processHistoryBreakdownHashes(tagBreakdownResults)) organisationReport.tagsWithHistory[key] = true
//   }
// }

// private processHistoryBreakdown(breakdown: ReportBreakdown, historyBreakdown: ReportBreakdown) {
//   let historyAvailable = false
//   if (historyBreakdown) {
//     if (breakdown.average_points && historyBreakdown.average_points) {
//       historyAvailable = true
//       breakdown.average_points_delta = this.getRoundedDelta(breakdown.average_points, historyBreakdown.average_points)
//     }

//     if (breakdown.answer_percentages && historyBreakdown.answer_percentages) {
//       historyAvailable = true
//       breakdown.answer_percentages_delta = breakdown.answer_percentages.map((answerPercentage, i) => {
//         return this.getRoundedDelta(answerPercentage, historyBreakdown.answer_percentages[i])
//       })
//     }
//   }

//   return historyAvailable
// }

// private processHistoryBreakdownHashes(historyBreakdowns: { [key: string]: ReportBreakdown }[]) {
//   if (historyBreakdowns.length < 2) return

//   const currentBreakdowns = historyBreakdowns[0]

//   let historyAvailable = false

//   for (const key of Object.keys(currentBreakdowns)) {
//     const breakdown = currentBreakdowns[key]
//     let historyBreakdown: ReportBreakdown

//     // Look for the breakdown in history, skipping the current report
//     for (let i = 1; i < historyBreakdowns.length; i++) {
//       const historyBreakdownsObj = historyBreakdowns[i]
//       if (historyBreakdownsObj[key]) {
//         historyBreakdown = historyBreakdownsObj[key]
//         break
//       }
//     }

//     const hadHistory = this.processHistoryBreakdown(breakdown, historyBreakdown)
//     historyAvailable = historyAvailable || hadHistory
//   }

//   return historyAvailable
// }

// private processOrganisationHistory(organisationReport: OrganisationReport) {
//   const instances = organisationReport.instances.filter(instance => !instance.organisation.insufficient_data)

//   const organisationResults = instances
//     .map(instance => instance.organisation)
//     .filter(result => !!result && !result.insufficient_data)

//   // Total
//   if (organisationResults.length > 1) this.processHistoryBreakdown(organisationResults[0], organisationResults[1])

//   // Question breakdowns
//   const organisationBreakdownResults = instances
//     .map(instance => (instance.organisation || ({} as any)).breakdown)
//     .filter(result => !!result)

//   this.processHistoryBreakdownHashes(organisationBreakdownResults)

//   // Category breakdowns
//   const teamBreakdownResults = instances
//     .map(instance => (instance.organisation || ({} as any)).category_breakdown)
//     .filter(result => !!result)

//   this.processHistoryBreakdownHashes(teamBreakdownResults)
// }

// private processTeamHistory(organisationReport: OrganisationReport) {
//   const currentInstance = organisationReport.instances[0]
//   const teamKeysWithData = Object.keys(currentInstance.teams).filter(
//     key => !currentInstance.teams[key].insufficient_data
//   )

//   for (const key of teamKeysWithData) {
//     const teamResults = organisationReport.instances.map(instance => instance.teams[key]).filter(result => !!result)

//     if (teamResults.length > 1) {
//       if (this.processHistoryBreakdown(teamResults[0], teamResults[1]))
//         organisationReport.teamsWithHistory[key] = true
//     }
//   }

//   // Team question breakdowns
//   for (const key of teamKeysWithData) {
//     const teamBreakdownResults = organisationReport.instances
//       .map(instance => (instance.teams[key] || ({} as any)).breakdown)
//       .filter(result => !!result)

//     if (this.processHistoryBreakdownHashes(teamBreakdownResults)) organisationReport.teamsWithHistory[key] = true
//   }

//   // Team category breakdowns
//   for (const key of teamKeysWithData) {
//     const teamBreakdownResults = organisationReport.instances
//       .map(instance => (instance.teams[key] || ({} as any)).category_breakdown)
//       .filter(result => !!result)

//     if (this.processHistoryBreakdownHashes(teamBreakdownResults)) organisationReport.teamsWithHistory[key] = true
//   }
// }

// private processTeamTagHistory(organisationReport: OrganisationReport) {
//   const currentInstance = organisationReport.instances[0]
//   const teamKeysWithData = Object.keys(currentInstance.teams).filter(
//     key => !currentInstance.teams[key].insufficient_data
//   )

//   for (const teamKey of teamKeysWithData) {
//     const team = currentInstance.teams[teamKey]

//     if (!team || !team.tags) continue

//     const tagKeysWithData = Object.keys(team.tags).filter(
//       key => currentInstance.tags[key] && !currentInstance.tags[key].insufficient_data
//     )

//     // If we have a shared report with breakdown tags selected
//     // they might not be included in the dataintance.tags
//     if (tagKeysWithData.length === 0) {
//       const metadata = this.doc<ReportDataInstanceMetadata>(organisationReport.id, "ReportDataInstanceMetadata")
//       const uiState = metadata ? metadata.ui_state : undefined
//       if (uiState && uiState.selected_tag) {
//         tagKeysWithData.push(uiState.selected_tag)
//       }
//     }

//     for (const tagKey of tagKeysWithData) {
//       const tagResults = organisationReport.instances
//         .map(
//           instance =>
//             instance.teams[teamKey] &&
//             instance.teams[teamKey].tags &&
//             (instance.teams[teamKey].tags[tagKey] || ({} as any))
//         )
//         .filter(result => !!result)

//       if (tagResults.length > 1) this.processHistoryBreakdown(tagResults[0], tagResults[1])
//     }

//     // Team tag question breakdowns
//     for (const tagKey of tagKeysWithData) {
//       const tagBreakdownResults = organisationReport.instances
//         .map(
//           instance =>
//             instance.teams[teamKey] &&
//             instance.teams[teamKey].tags &&
//             (instance.teams[teamKey].tags[tagKey] || ({} as any)).breakdown
//         )
//         .filter(result => !!result)

//       if (this.processHistoryBreakdownHashes(tagBreakdownResults)) organisationReport.tagsWithHistory[tagKey] = true
//     }

//     // Team tag category breakdowns
//     for (const tagKey of tagKeysWithData) {
//       const tagBreakdownResults = organisationReport.instances
//         .map(
//           instance =>
//             instance.teams[teamKey] &&
//             instance.teams[teamKey].tags &&
//             (instance.teams[teamKey].tags[tagKey] || ({} as any)).category_breakdown
//         )
//         .filter(result => !!result)

//       if (this.processHistoryBreakdownHashes(tagBreakdownResults)) organisationReport.tagsWithHistory[tagKey] = true
//     }
//   }
// }

/**
 * See if there are any questions that are present in the latest report but are missing from one of the
 * history instances
 */
// private hasQuestionWithMissingHistory(organisationReport: OrganisationReport) {
//   // Missing questions
//   for (const instance of organisationReport.instances) {
//     for (const question of organisationReport.questions) {
//       if (instance.organisation.breakdown && !instance.organisation.breakdown[question.id]) {
//         return true
//       }
//     }
//   }

//   return false
// }

// private getRoundedDelta(newValue: number, oldValue: number) {
//   const roundedNew = Math.round(newValue * 10) / 10
//   const roundedOld = Math.round(oldValue * 10) / 10
//   return roundedNew - roundedOld
// }

// private filterInstancesBasedOnIndexList = (indices, organisationReport) => {
//   try {
//     organisationReport.instances = organisationReport.instances.filter((survey, index) => {
//       return indices.indexOf(index) !== -1
//     })
//   } catch (e) {
//     this.logger.error("Failed to parse history index list", e)
//   }
// }
