import { consoleLoggerConfig } from "core/modules/config/loggerConfig"

import { BaseLogger, LogLevel } from "./BaseLogger"

// tslint:disable:no-console

/**
 * Logs messages to console output
 */
export class ConsoleLogger extends BaseLogger {
  constructor(name: string) {
    super(name, consoleLoggerConfig.logLevel)
  }

  getChildLogger(name: string): BaseLogger {
    return new ConsoleLogger(name)
  }

  // biome-ignore lint/suspicious/noExplicitAny: content can be anything
  writeLogLine(logLevel: LogLevel, ...content: any[]): void {
    const logFn =
      {
        [LogLevel.Error]: console.error,
        [LogLevel.Warning]: console.warn,
        [LogLevel.Info]: console.info,
        [LogLevel.Debug]: console.debug
      }[logLevel] ?? console.log

    logFn(`<${this.name}>`, ...content)
  }

  startGroup(name: string, collapsed = false) {
    collapsed ? console.groupCollapsed(name) : console.group(name)
  }

  endGroup() {
    console.groupEnd()
  }
}
