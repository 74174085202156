import classNames from "classnames"
import { HttpError } from "core/modules/state/model/Model"
import { style } from "typestyle"
import BaseInput, { BaseInputProps } from "./BaseInput"

interface ErrorFieldProps extends BaseInputProps {
  error: HttpError
}

export default class ErrorField extends BaseInput<ErrorFieldProps, {}> {
  get componentName() {
    return ["ui", "form", "ErrorField"]
  }

  private get errorClass(): string {
    return style({
      paddingLeft: "0.5em",
      fontSize: "1.3em",
      color: this.theme.colors.errorColor.toString()
    })
  }

  render() {
    return (
      <div className={classNames(this.props.className, this.errorClass)}>
        <span>{this.txt("ui", "error", this.props.error.error)}</span>
      </div>
    )
  }
}
