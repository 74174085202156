import { appName } from "../../AppName"

export const uiTranslations = {
  [appName]: {
    components: {
      choose: {
        en: "Search users and teams",
        fi: "Etsi käyttäjiä ja tiimejä"
      },
      search_for: {
        en: "Search for",
        fi: "Hae"
      },
      reports: {
        AnswerView: {
          average_score: {
            en: "Average score: {0}",
            fi: "Keskiarvo: {0}"
          },
          previous_score_title: {
            en: "previous",
            fi: "edellinen",
            ru: "назад",
            pl: "wstecz",
            et: "eelmine",
            sv: "föregående",
            lv: "iepriekšējais",
            lt: "ankstesnis",
            de: "vorherige",
            no: "forrige",
            da: "forrige",
            es: "anterior"
          }
        },
        GASScaleView: {
          much_worse_than_expected_title: {
            da: "Meget mindre end forventet",
            de: "Viel weniger als erwartet",
            en: "Much worse than expected",
            es: "Mucho peor de lo esperado",
            et: "Oodatust palju vähem",
            fi: "Paljon odotettua vähemmän",
            no: "Mye mindre enn forventet",
            sv: "Mycket mindre än förväntat",
            pl: "Znacznie poniżej oczekiwań",
            ru: "Гораздо меньше, чем ожидалось",
            fr: "Bien pire que prévu",
            pt: "Muito pior do que esperado",
            zh: "比预期差很多"
          },
          slightly_worse_than_expected_title: {
            da: "Lidt mindre end forventet",
            de: "Etwas weniger als erwartet",
            en: "Slightly worse than expected",
            es: "Ligeramente peor de lo esperado",
            et: "Oodatust pisut vähem",
            fi: "Hieman odotettua vähemmän",
            no: "Litt mindre enn forventet",
            sv: "Något mindre än förväntat",
            pl: "Nieco poniżej oczekiwań",
            ru: "Немного меньше, чем ожидалось",
            fr: "Légèrement pire que prévu",
            pt: "Pior do que esperado",
            zh: "稍逊于预期"
          },
          as_expected_title: {
            da: "Som forventet",
            de: "Wie erwartet",
            en: "As expected",
            es: "Como se esperaba",
            et: "Vastas ootustele",
            fi: "Odotetusti",
            no: "Som forventet",
            sv: "Som förväntat",
            pl: "Zgodnie z oczekiwaniami",
            ru: "Как и ожидалось",
            fr: "Comme prévu",
            pt: "Como esperado",
            zh: "符合预期"
          },
          slightly_better_than_expected_title: {
            da: "Lidt bedre end forventet",
            de: "Etwas besser als erwartet",
            en: "Slightly better than expected",
            es: "Ligeramente mejor de lo esperado",
            et: "Oodatust pisut parem",
            fi: "Hieman odotettua paremmin",
            no: "Litt bedre enn forventet",
            sv: "Något bättre än förväntat",
            pl: "Nieco powyżej oczekiwań",
            ru: "Немного лучше, чем ожидалось",
            fr: "Légèrement mieux que prévu",
            pt: "Ligeiramente melhor do que esperado",
            zh: "略超预期"
          },
          much_better_than_expected_title: {
            da: "Meget mindre end forventet",
            de: "Viel besser als erwartet",
            en: "Much better than expected",
            es: "Mucho mejor de lo esperado",
            et: "Oodatust palju parem",
            fi: "Paljon odotettua paremmin",
            no: "Mye bedre enn forventet",
            sv: "Mycket bättre än förväntat",
            pl: "Znacznie powyżej oczekiwań",
            ru: "Гораздо лучше, чем ожидалось",
            fr: "Bien mieux que prévu",
            pt: "Muito melhor do que esperado",
            zh: "比预期好很多"
          }
        },
        response_rates_list: {
          ResponseRatesList: {
            teams: {
              en: "Team",
              fi: "Tiimi"
            },
            tags: {
              en: "Tag",
              fi: "Tunniste"
            },
            survey: {
              en: "Survey",
              fi: "Koko kysely"
            },
            participants: {
              en: "Participants",
              fi: "Vastaukset"
            },
            can_generate_report: {
              en: "Report can be generated",
              fi: "Raportti voidaan generoida"
            },
            shown_in_report: {
              en: "Will be shown in report",
              fi: "Näkyy raportissa"
            },
            error_too_few_answers: {
              en: "Too few answers",
              fi: "Liian vähän vastauksia"
            },
            error_too_similar: {
              en: "Too similar resultset",
              fi: "Liian samankaltainen"
            },
            too_similar_tip: {
              en: "Resultset is too close to another team or tag and results cannot be displayed to protect respondents' privacy.",
              fi: "Vastaajajoukko on liian lähellä toista tiimiä tai tunnistetta. Yksityisyyssyistä tuloksia ei voida näyttää."
            },
            too_few_answers_tip: {
              en: "Results cannot be displayed due to privacy reasons. You need to have at least {0} responses.",
              fi: "Koska vastaajia on alle {0} tuloksia ei voida näyttää yksiyisyyssyistä."
            }
          }
        },
        ResponseRates: {
          search_placeholder: {
            en: "Search teams and tags",
            fi: "Etsi tiimejä ja tunnisteita"
          }
        }
      },
      ui: {
        form: {
          BaseInput: {
            none: {
              en: "None",
              fi: "Ei valintaa"
            }
          },
          DateField: {
            week: {
              en: "CW {0} {1}",
              fi: "VK {0} {1}"
            },
            label_mon: {
              en: "Mon",
              fi: "Ma"
            },
            label_tu: {
              en: "Tu",
              fi: "Ti"
            },
            label_wed: {
              en: "Wed",
              fi: "Ke"
            },
            label_thu: {
              en: "Thu",
              fi: "To"
            },
            label_fri: {
              en: "Fri",
              fi: "Pe"
            },
            label_sat: {
              en: "Sat",
              fi: "La"
            },
            label_sun: {
              en: "Sun",
              fi: "Su"
            },
            label_january: {
              en: "January",
              fi: "Tammikuu"
            },
            label_february: {
              en: "February",
              fi: "Helmikuu"
            },
            label_march: {
              en: "March",
              fi: "Maaliskuu"
            },
            label_april: {
              en: "April",
              fi: "Huhtikuu"
            },
            label_may: {
              en: "May",
              fi: "Toukokuu"
            },
            label_june: {
              en: "June",
              fi: "Kesäkuu"
            },
            label_july: {
              en: "July",
              fi: "Heinäkuu"
            },
            label_august: {
              en: "August",
              fi: "Elokuu"
            },
            label_september: {
              en: "September",
              fi: "Syyskuu"
            },
            label_october: {
              en: "October",
              fi: "Lokakuu"
            },
            label_november: {
              en: "November",
              fi: "Marraskuu"
            },
            label_december: {
              en: "December",
              fi: "Joulukuu"
            }
          }
        }
      },
      cards: {
        NumberCard: {
          millions: {
            en: "millions",
            fi: "miljoonaa"
          },
          billions: {
            en: "billions",
            fi: "miljardia"
          }
        },
        LineChartCard: {
          insufficient_data: {
            en: "Insufficient data",
            fi: "Ei tarpeeksi tuloksia"
          }
        },
        BarChartCard: {
          insufficient_data: {
            en: "Insufficient data",
            fi: "Ei tarpeeksi tuloksia"
          }
        }
      },
      list: {
        ListView: {
          global_select_all: {
            en: "Select all {0} items from every page",
            fi: "Valitse kaikki {0} kohdetta joka sivulta"
          },
          global_select_all_except: {
            en: ", except the separately selected {0} users",
            fi: ", lukuunottamatta {0} erikseen valittua käyttäjää"
          },
          no_content: {
            en: "No content",
            fi: "Ei sisältöä"
          }
        },
        ListColumnSelection: {
          columns: {
            en: "Choose Columns",
            fi: "Valitse sarakkeet"
          }
        },
        ListControls: {
          number_of_selected_users: {
            en: "{0} selected items",
            fi: "{0} valittua kohdetta"
          }
        }
      },
      LoginDialog: {
        email_hint: {
          en: "You will receive a one-time authentication token to this email",
          fi: "Saat sähköpostiisi kertakäyttöisen tunnuksen"
        },
        email_label: {
          en: "Please enter your email",
          fi: "Syötä sähköpostiosoitteesi"
        },
        mfa_token_label: {
          en: "MFA token (optional)",
          fi: "Kaksivaiheinen tunnistus (valinnainen)"
        },
        remember_me: {
          en: "Remember me for two weeks",
          fi: "Muista kirjautuminen kaksi viikkoa"
        },
        token_description: {
          en: `
Now enter the one-time authentication token we sent to your email.
If you have multi-factor authentication enabled, you should also receive a separate token by SMS.
`,
          fi: `
Syötä sähköpostiisi lähetetty kertakäyttöinen tunnus.
Jos sinulla on kaksivaiheinen tunnistus käytössä, saat erillisen tunnuksen myös tekstiviestillä.
`
        },
        token_label: {
          en: "Authentication token",
          fi: "Tunnus"
        }
      },
      settings: {
        LanguageSelectorDropdown: {
          chooseLanguage: {
            en: "Select language",
            fi: "Valitse kieli (select language)",
            da: "Vælg sprog (select language)",
            de: "Sprache wählen (select language)",
            et: "Vali keel (select language)",
            no: "Velg språk (select language)",
            sv: "Välj språk (select language)",
            es: "Seleccione el idioma (select language)",
            ru: "Выберите язык (select language)",
            pl: "Wybierz język (select language)",
            lv: "Izvēlieties valodu (select language)",
            lt: "Pasirinkite kalbą (select language)",
            fr: "Choisissez la langue (select language)",
            pt: "Selecione o idioma (select language)",
            zh: "选择语言 (select language)"
          }
        },
        SettingsSelector: {
          logout: {
            en: "Log out",
            fi: "Kirjaudu ulos"
          },
          select_organisation: {
            en: "Select organisation",
            fi: "Valitse organisaatio"
          }
        }
      }
    },
    error: {
      invalid_grant: {
        en: "Invalid authentication token",
        fi: "Koodia ei tunnistettu"
      },
      invalid_mfa_token: {
        en: "Invalid multi-factor authentication token",
        fi: "Kaksivaiheisen tunnistautumisen koodia ei tunnistettu"
      }
    }
  }
}
