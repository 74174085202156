export enum LogLevel {
  Undefined = 0,
  Error,
  Warning,
  Info,
  Debug,
  Trace
}

export interface Logger {
  name: string
  minLogLevel: LogLevel
  getChildLogger(name: string): Logger
  errorAndThrow(message: string, params?: any): void
  error(message: string, params?: any): void
  warning(message: string, params?: any): void
  info(message: string, params?: any): void
  debug(message: string, params?: any): void
  trace(message: string, params?: any): void
  log(logLevel: LogLevel, message: string, params?: any): void
  startGroup(name: string, collapsed?: boolean): void
  endGroup(): void
}

export abstract class BaseLogger implements Logger {
  protected constructor(
    readonly name: string,
    public minLogLevel: LogLevel
  ) {}

  abstract getChildLogger(name: string): BaseLogger

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  error(message: string, params?: any): void {
    this.log(LogLevel.Error, message, params)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  errorAndThrow(message: string, params?: any) {
    this.log(LogLevel.Error, message, params)
    throw new Error(message)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  warning(message: string, params?: any): void {
    this.log(LogLevel.Warning, message, params)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  info(message: string, params?: any): void {
    this.log(LogLevel.Info, message, params)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  debug(message: string, params?: any): void {
    this.log(LogLevel.Debug, message, params)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  trace(message: string, params?: any): void {
    this.log(LogLevel.Trace, message, params)
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  log(logLevel: LogLevel, message: string, params?: any): void {
    if (logLevel > this.minLogLevel) return

    this.writeLogLine(logLevel, message, params)
  }

  startGroup(_name: string, _collapsed?: boolean) {
    // Default implementation is to do nothing
  }

  endGroup() {
    // Default implementation is to do nothing
  }

  /**
   * Override to write into log. Gets called if log level is high enough.
   */
  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  protected abstract writeLogLine(logLevel: LogLevel, message: string, params?: any): void
}
