import { Notifier as AirbrakeClient } from "@airbrake/browser"

import { airbrakeLoggerConfig } from "core/modules/config/loggerConfig"

import { BaseLogger, LogLevel } from "./BaseLogger"

/**
 * Logs messages to a remote endpoint
 */
export class AirbrakeLogger extends BaseLogger {
  static airbrakeInstance: AirbrakeClient | undefined

  constructor(name: string) {
    const { environment, keysBlocklist, logLevel, projectId, projectKey, version } = airbrakeLoggerConfig

    super(name, logLevel)

    if (!AirbrakeLogger.airbrakeInstance) {
      AirbrakeLogger.airbrakeInstance = new AirbrakeClient({ projectId, projectKey, environment, keysBlocklist })

      AirbrakeLogger.airbrakeInstance.addFilter(notice => {
        // Skip React error on staging
        if (
          notice.errors?.[0]?.message.includes(
            "React is running in production mode, but dead code elimination has not been applied"
          )
        ) {
          return null
        }

        // Log app version
        notice.context.version = `v${version}`

        return notice
      })
    }
  }

  getChildLogger(_name: string): BaseLogger {
    // AirbrakeLogger does not support child loggers, just return itself
    return this
  }

  // biome-ignore lint/suspicious/noExplicitAny: params can be anything
  writeLogLine(logLevel: LogLevel, message: string, params?: any): void {
    // Write only errors and warnings to Airbrake
    const severity: "error" | "warning" =
      {
        [LogLevel.Error]: "error",
        [LogLevel.Warning]: "warning"
      }[logLevel] ?? "warning"

    let error: Error | undefined

    if (severity) {
      // Find Error object if any and inject message to params
      if (params instanceof Error) {
        error = params
        params = { message }
      } else if (Array.isArray(params)) {
        error = params.find(e => e instanceof Error)
        params = { message, params: params.filter(p => p !== error) }
      } else if (typeof params === "object" && "error" in params) {
        params = { message, ...params }
        error = params.error
        delete params.error
      } else {
        params = { message, params }
      }

      AirbrakeLogger.airbrakeInstance?.notify({ error, context: { severity }, params })
    }
  }
}
