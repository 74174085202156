import { ColorHelper, color, rgba } from "csx"

import { BaseModuleWithAppName } from "core/controller/Module"

export interface ThemeConstants {
  disabledTextColor: ColorHelper
  disabledBackgroundColor: ColorHelper
  negativeColor: ColorHelper
  positiveColor: ColorHelper
  neutralColor: ColorHelper
  primaryColor: ColorHelper
  secondaryColor: ColorHelper
  tertiaryColor: ColorHelper
  baseGray: ColorHelper
  disabledColor: ColorHelper
  faintColor: ColorHelper
  fontColor: ColorHelper
  backgroundColor: ColorHelper
  inputBackgroundColor: ColorHelper
  white: ColorHelper
  report_excellent: ColorHelper
  report_well_done: ColorHelper
  report_neutral: ColorHelper
  report_need_attention: ColorHelper
  report_need_extra_focus: ColorHelper
  menuFontColor: ColorHelper
  menuSelectedFontColor: ColorHelper
  background: string
}

export class HintsaThemeConstants extends BaseModuleWithAppName implements ThemeConstants {
  /**
   * Hintsa brand colors
   */
  get charcoalColor() {
    return color("#282828")
  }

  get graniteColor() {
    return color("#7C7871")
  }

  get alabasterColor() {
    return color("#D5D1CB")
  }

  get ivoryColor() {
    return color("#FFFEFA")
  }

  get tangerineColor() {
    return color("#537D81")
  }

  get flushedPinkColor() {
    return color("#FFE0D4")
  }

  get oatmealColor() {
    return color(" #F8F6E3")
  }

  get opalGreyColor() {
    return color("#B8CBC6")
  }

  get cobaltColor() {
    return color("#7E9CA7")
  }

  get midnightColor() {
    return color("#537D81")
  }

  get moduleName() {
    return "ThemeConstants"
  }

  /**
   * Config should typically be setup first
   */
  get setupPriority(): number {
    return 900
  }

  get colorPhysicalActivity() {
    return this.tangerineColor
  }

  get colorGeneralHealth() {
    return this.flushedPinkColor
  }

  get colorNutrition() {
    return this.oatmealColor
  }

  get colorMentalEnergy() {
    return this.opalGreyColor
  }

  get colorBiomechanics() {
    return this.cobaltColor
  }

  get colorSleepRecovery() {
    return this.midnightColor
  }

  primaryColor = this.charcoalColor
  secondaryColor = this.midnightColor
  tertiaryColor = this.alabasterColor
  fontColor = this.charcoalColor
  disabledTextColor = color("#d4d4cf")
  disabledBackgroundColor = color("#f2f0ed")
  // Darken the negative color to give more contrast
  negativeColor = color("#F5886C")
  positiveColor = color("#38A470")
  neutralColor = color("#E4C57A")
  baseGray = this.graniteColor
  disabledColor = this.alabasterColor
  faintColor = rgba(0, 0, 0, 0.05)
  backgroundColor = color("#f2f0ed")
  inputBackgroundColor = this.ivoryColor
  white = color("#FFF")
  menuFontColor = color("#FFF")
  menuSelectedFontColor = color("#FFF")

  report_excellent = color("#11EF7C")
  report_well_done = color("#CFEBB6")
  report_neutral = color("#FFE5B6")
  report_need_attention = color("#FFA6AA")
  report_need_extra_focus = color("#FF626B")
  report_benchmark = color("#FFBC7C")

  get background() {
    return "linear-gradient(90deg,#ea9f88 0%,#ffe0d4 20%,#f8f6e3 40%,#b8cbc6 60%,#7996a1 80%,#537d81 100%)"
  }
}
