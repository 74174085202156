import classNames from "classnames"
import { ViewComponentProps } from "core/components/base/ViewComponent"
import * as csx from "csx"
import * as React from "react"
import { style } from "typestyle"
import { ViewComponent } from "../base/ViewComponent"

interface CircleProps extends ViewComponentProps {
  diameter?: string
  color?: string | csx.ColorHelper
  className?: string
  shadow?: boolean
  onClick?: (event: React.SyntheticEvent<any>) => void
}

export default class Circle extends ViewComponent<CircleProps, {}> {
  get componentName(): string[] {
    return ["ui", "Circle"]
  }

  static defaultProps = { diameter: "1rem" }

  render() {
    super.render()

    let color: csx.ColorHelper =
      typeof this.props.color === "string" ? csx.color(this.props.color) : (this.props.color as csx.ColorHelper)

    const circleClass = style({
      borderRadius: "50%",
      width: this.props.diameter,
      height: this.props.diameter,
      backgroundColor: color?.toString(),
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    })

    return <div className={classNames(circleClass, this.props.className)}>{this.props.children}</div>
  }
}
