import {
  LogicComponent as BaseLogicComponent,
  LogicComponentProps,
  LogicComponentState
} from "core/components/base/LogicComponent"

export abstract class LogicComponent<
  P extends LogicComponentProps,
  S extends LogicComponentState | void = {}
> extends BaseLogicComponent<P, S> {
  get appName() {
    return "ui"
  }
}
