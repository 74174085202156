import MuiCheckbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import classNames from "classnames"
import type { ChangeEvent } from "react"
import { stylesheet } from "typestyle"

import type { BaseInputProps } from "./BaseInput"

interface Props<T extends string = string> extends BaseInputProps<boolean> {
  disabled?: boolean
  fullWidth?: boolean
  indeterminate?: boolean
  margin?: boolean
  name?: T
  onChange?: (value: boolean, name: T) => void
  required?: boolean
}

export default function CheckBox<T extends string>({
  className,
  disabled,
  fullWidth,
  id,
  indeterminate,
  label,
  labelClass,
  onChange,
  margin,
  name,
  value
}: Props<T>) {
  function handleChange({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) {
    onChange?.(checked, name as T)
  }

  return (
    <FormControlLabel
      className={classNames(fullWidth && styles.fullWidth, margin !== false && styles.margin, labelClass)}
      control={
        <MuiCheckbox
          id={id}
          className={className}
          checked={!!value}
          color="secondary"
          disabled={disabled}
          indeterminate={indeterminate}
          name={name}
          onChange={handleChange}
        />
      }
      label={label}
      onClick={event => event.stopPropagation()}
    />
  )
}

const styles = stylesheet({
  fullWidth: { width: "100%" },
  margin: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem"
  }
})
