import { ContainerManager } from "./ContainerManager"
import { Doc, Link, ViewContainer } from "./Model"
import { ModelManagerInternal } from "./ModelManager"
import { Logger } from "../../../modules/logging/Logger"
import { CoreApi } from "../../../modules/api/CoreApi"

export interface View {
  documents: Doc[]
  parameters: any
  raw: any
  valid: boolean
  loaded: boolean
}

export class ViewContainerManager<T extends Doc> extends ContainerManager implements View {
  api: CoreApi

  get documents(): T[] {
    if (!this.container) return []

    const base = this
    const documents = []

    // Make sure all documents are present. Don't return removed documents
    for (const link of this.container.array) {
      const document = base.modelManager.getDocument(link.id, link.__type)
      if (document) documents.push(document)
    }

    return documents
  }

  get parameters(): any {
    if (!this.container) return {}

    return this.container.parameters || {}
  }

  set parameters(parameters: any) {
    if (!this.container) return

    this.container.parameters = parameters
  }

  get raw(): any {
    if (!this.container) return {}

    return this.container.raw ? this.container.raw : {}
  }

  set raw(raw: any) {
    if (!this.container) return

    this.container.raw = raw
  }

  get valid() {
    return this._valid
  }

  get loaded() {
    return !!this.container
  }

  private container: ViewContainer<Doc>
  private _initialized = false
  private _valid = true

  constructor(container: ViewContainer<Doc>, modelManager: ModelManagerInternal, logger: Logger) {
    super()
    this.container = container
    this.modelManager = modelManager
    this.logger = logger

    if (!container) return // This is a dummy container with no contents

    if (!container.documentType) throw new Error(`View container missing document type`)

    if (!container.array) {
      this.logger.debug("Container didn't have an array. Creating it.")
      container.array = []
    }
  }

  /**
   * Append link to a given document to the view's end
   */
  push(document): void {
    if (!this.container) return

    this.verifyStateModify()
    this.setDirtyFlag()

    const id: string = (<any>document).id

    const link: Link<Doc> = {
      __type: this.container.documentType,
      id
    }

    this.container.array.push(link)
  }

  /**
   * Removes document link from this view
   */
  removeDocumentWithIndex(index: number) {
    this.verifyStateModify()
    this.setDirtyFlag()
  }

  /**
   * Removes document link from this view
   */
  removeDocumentWithId(id: string) {
    this.verifyStateModify()
    this.setDirtyFlag()

    for (let i = 0, documents = this.container.array, length = documents.length; i < length; i++) {
      if ((<Link<Doc>>documents[i]).id === id) {
        documents.splice(i, 1)
        break
      }
    }
  }

  initialize() {
    this.verifyStateModify()
    this.container.array = []
    this._dirty = false
    this._initialized = true
    this._valid = true
  }

  markAsInvalid() {
    this._valid = false
  }

  /**
   * In case of any mutation, the unerlying data structures will be cloned and dirty flag set. We do not wish to
   * clone always due to performance cost.
   */
  private setDirtyFlag() {
    if (this._dirty) return

    this.logger.trace("Dirty flag set. Cloning view container", this.container.viewName)

    this.container = this.modelManager.cloneViewContainer(this.container.viewName)
    this.container.array = this.container.array.slice(0)
    this._dirty = true
  }
}
