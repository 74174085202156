import URIStatic from "urijs"

import { ApiConfig } from "core/modules/config/apiConfig"
import { HttpError } from "core/modules/state/model/Model"
import { oauthApiConfig } from "lib/authentication/modules/config/oauthApiConfig"

import { BaseModule } from "../../controller/Module"
import { AccessToken } from "../state/model/Model"

export interface OauthApi {
  accessToken?: AccessToken

  loginWithToken(token: string, email: string, mfaToken: string, scope?: string): Promise<Response>
  loginWithSurveyCode(companyCode: string, surveyCode: string): Promise<Response>
  logout(): Promise<Response>
  getHttpError(status: number, json: { error: string; errorDetails?: string }): HttpError
}

export class OauthApiModule extends BaseModule implements OauthApi {
  constructor(protected apiConfig: ApiConfig = oauthApiConfig) {
    super()
  }

  get moduleName() {
    return "OauthApi"
  }

  protected _accessToken?: AccessToken

  get accessToken(): AccessToken | undefined {
    return this._accessToken
  }

  set accessToken(accessToken) {
    this._accessToken = accessToken
  }

  async loginWithToken(token: string, email: string, mfaToken: string, scope?: string) {
    this.logger.info("Log in with token", { token, mfaToken, email })

    const body = {
      client_id: this.apiConfig.clientId,
      client_secret: this.apiConfig.clientSecret,
      email,
      grant_type: "magic_token",
      magic_token: token,
      mfa_token: mfaToken,
      scope
    }

    return await fetch(
      URIStatic(this.apiConfig.apiRoot)
        .segment(this.apiConfig.apiRelativePath.concat(["token"]))
        .toString(),
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body)
      }
    )
  }

  async loginWithSurveyCode(companyCode: string, surveyCode: string) {
    this.logger.info("Log in with company and survey code", { companyCode, surveyCode })

    const body = {
      client_id: this.apiConfig.clientId,
      client_secret: this.apiConfig.clientSecret,
      company_code: companyCode,
      grant_type: "survey_code",
      survey_code: surveyCode
    }

    return await fetch(
      URIStatic(this.apiConfig.apiRoot)
        .segment(this.apiConfig.apiRelativePath.concat(["token"]))
        .toString(),
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(body)
      }
    )
  }

  async logout() {
    this.logger.info("Logging out")

    this.checkAccess()

    const body = {
      token: this.accessToken?.access_token,
      client_id: this.apiConfig.clientId,
      client_secret: this.apiConfig.clientSecret
    }

    return await fetch(
      URIStatic(this.apiConfig.apiRoot)
        .segment(this.apiConfig.apiRelativePath.concat(["revoke"]))
        .toString(),
      {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(body)
      }
    )
  }

  public getHttpError(status: number, json: { error: string; errorDetails?: string }): HttpError {
    return {
      error: json.error,
      httpStatusCode: status,
      error_description: json.errorDetails
    }
  }

  private checkAccess() {
    if (!this.accessToken || !this.accessToken.access_token) {
      throw new Error("Access token missing")
    }
  }
}
