import { BaseComponent, type BaseComponentProps, type BaseComponentState } from "core/components/base/BaseComponent"

export interface ViewComponentProps extends BaseComponentProps {}

export interface ViewComponentState extends BaseComponentState {}

export abstract class ViewComponent<
  P extends ViewComponentProps,
  S extends ViewComponentState | void
> extends BaseComponent<P, S> {
  protected appConfig: Record<string, unknown> = {}
}
