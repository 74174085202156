import classNames from "classnames"
import * as React from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"
import { HttpError } from "core/modules/state/model/Model"

import { ViewComponent } from "../base/ViewComponent"
import ErrorField from "./ErrorField"
import { PropsWithChildren } from 'react'

interface FormProps extends ViewComponentProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  className?: string
  error?: HttpError
  id?: string
  autocomplete?: string
}

export default class Form extends ViewComponent<PropsWithChildren<FormProps>> {
  get componentName(): string[] {
    return ["ui", "form", "Form"]
  }

  render() {
    const { autocomplete } = this.props

    return (
      <form
        id={this.props.id}
        className={classNames(this.props.className)}
        onSubmit={this.props.onSubmit}
        autoComplete={autocomplete}
      >
        {this.props.children}
        {this.renderError()}
      </form>
    )
  }

  private renderError() {
    return this.props.error ? <ErrorField error={this.props.error} /> : undefined
  }
}
