export const slugifyFileName = (text: string) =>
  text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
    .replace(/,+/g, "") // Replace commas
    .replace(/[\/\\:*?"<>]/g, "") // Replace characters that may cause problems in file names
