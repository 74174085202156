import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import IconButton from "@material-ui/core/IconButton/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputBase, { type InputBaseProps } from "@material-ui/core/InputBase"
import { type Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as csx from "csx"

import BaseInput from "./BaseInput"

const styles = ({ transitions }: Theme) =>
  createStyles({
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    fullWidth: {
      width: "100%"
    },
    icon: {
      marginLeft: "0.5rem",
      marginRight: 0,
      color: TextField.theme.colors.baseGray.toString()
    },
    "&.Mui-focused fieldset": {
      borderColor: TextField.theme.colors.primary.toString()
    },
    endAdornmentForUnit: {
      // This is a bit hacky, added due to MUI wanting to have adornments inside the input
      width: "4rem",
      marginRight: "-4rem"
    },
    paddingForUnit: {
      paddingRight: "3rem"
    },
    inputBaseError: {
      borderColor: csx.important(TextField.theme.colors.errorColor.toString()),
      "&:focus": {
        borderColor: csx.important(TextField.theme.colors.errorColor.toString())
      }
    },
    multiline: {
      padding: "0.5rem"
    },
    noBorder: {
      border: csx.important("none")
    }
  })

export interface TextFieldProps extends Omit<InputBaseProps, "margin" | "onChange"> {
  clearable?: boolean
  error?: boolean
  focus?: boolean
  showErrorOnFocus?: boolean
  helperText?: string
  label?: string
  length?: number
  margin?: boolean
  max?: number
  min?: number
  multiline?: boolean
  noBorder?: boolean
  unit?: string
  defaultValue?: string
  onChange?: (value: string, name?: string) => void
}

class TextField extends BaseInput<TextFieldProps & WithStyles<typeof styles>, {}> {
  get componentName() {
    return ["ui", "form", "TextField"]
  }

  render() {
    const {
      classes,
      clearable,
      focus,
      showErrorOnFocus,
      helperText,
      id,
      name,
      startAdornment,
      type,
      value,
      margin,
      fullWidth,
      multiline,
      rows,
      placeholder,
      disabled,
      length: maxLength,
      min,
      max,
      className,
      error,
      required,
      autoComplete,
      unit,
      noBorder
    } = this.props

    const icon = startAdornment && (
      <InputAdornment className={classes.icon} position="start">
        {startAdornment}
      </InputAdornment>
    )

    const endAdornment = (unit || clearable) && (
      <>
        {unit && (
          <InputAdornment className={classes.endAdornmentForUnit} position="end">
            {unit}
          </InputAdornment>
        )}
        {clearable && value && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => this.props.onChange?.("", this.props.name)}>
              ✕
            </IconButton>
          </InputAdornment>
        )}
      </>
    )

    return (
      <FormControl
        error={error}
        className={classNames(
          className,
          margin !== false && classes.margin,
          fullWidth && classes.fullWidth,
          unit && classes.paddingForUnit
        )}
      >
        {this.renderLabel()}
        <InputBase
          className={classNames(this.inputBaseClass, error && classes.inputBaseError)}
          autoFocus={focus}
          required={required}
          id={id}
          value={value || ""}
          name={name}
          placeholder={placeholder}
          type={type}
          fullWidth={fullWidth}
          multiline={multiline}
          error={!!error}
          inputProps={{ maxLength, min, max }}
          classes={{
            input: classNames(
              error && showErrorOnFocus !== false ? this.inputClassNoFocus : this.inputClass,
              error && classes.inputBaseError,
              multiline && classes.multiline,
              noBorder && classes.noBorder
            ),
            root: this.inputBaseClass
          }}
          startAdornment={icon}
          endAdornment={endAdornment}
          rows={rows}
          autoComplete={autoComplete}
          disabled={disabled}
          onChange={this.onChange}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  private onChange: InputBaseProps["onChange"] = event => {
    const { name, onChange } = this.props

    onChange?.(event.currentTarget.value, name)
  }
}

export default withStyles(styles)(TextField)
