import { BaseModuleWithAppName } from "core/controller/Module"
import type { LocalizationConfig } from "core/modules/config/localizationConfig"
import { I18n } from "core/modules/localization/I18n"
import { LocalizationState, TranslatedString } from "core/modules/state/model/Model"
import { ModelManager } from "core/modules/state/model/ModelManager"

export interface Localization extends LocalizationConfig {
  currentLanguage: string

  txt(...key: (string | TranslatedString | Record<string, string | number> | (string | number)[])[]): string
}

export class LocalizationModule extends BaseModuleWithAppName implements Localization {
  declare modelManager: ModelManager

  get moduleName() {
    return "Localization"
  }

  get dependencies() {
    return ["ModelManager"]
  }

  get currentLanguage() {
    return this.modelManager.getDefaultDocument<LocalizationState>("LocalizationState").language || I18n.defaultLanguage
  }

  get defaultLanguage() {
    return I18n.defaultLanguage
  }

  get dateFormat() {
    return I18n.config.dateFormat
  }

  get dateTimeFormat() {
    return I18n.config.dateTimeFormat
  }

  get decimalSeparator() {
    return I18n.config.decimalSeparator
  }

  get enabledLanguages() {
    return I18n.config.enabledLanguages
  }

  get surveyLanguages() {
    return I18n.config.surveyLanguages
  }

  txt = (...keys: (string | TranslatedString | Record<string, string | number> | (string | number)[])[]): string => {
    return I18n.txtWithLanguage(this.currentLanguage)(...keys)
  }
}
