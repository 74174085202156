import classNames from "classnames"
import * as React from "react"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../base/ViewComponent"

interface GlyphViewProps extends ViewComponentProps {
  data?: any
  glyph: string
  glyphClass?: string
  glyphType: GlyphType
  onClick?: (event: React.SyntheticEvent<any>) => void
}

export type GlyphType = "materialize" | "string" | "svg"

export default class Glyph extends ViewComponent<GlyphViewProps, {}> {
  get componentName(): string[] {
    return ["ui", "Glyph"]
  }

  render() {
    const props = this.props

    super.render()

    if (this.props.glyphType === "materialize")
      return (
        <i onClick={props.onClick} data-data={props.data} className={this.getGlyphClass()}>
          {this.renderGlyphContent()}
        </i>
      )
    else
      return (
        <span onClick={props.onClick} data-data={props.data} className={this.getGlyphClass()}>
          {this.renderGlyphContent()}
        </span>
      )
  }

  private getGlyphClass() {
    if (this.props.glyphType === "materialize") {
      return classNames(this.props.glyphClass, "material-icons")
    } else if (this.props.glyphType === "string") {
      return this.props.glyphClass
    }
  }

  private renderGlyphContent() {
    if (this.props.glyphType === "materialize") {
      return this.props.glyph
    } else if (this.props.glyphType === "string") {
      return this.props.glyph
    }

    return undefined
  }
}
