export const coreTranslations = {
  languages: {
    en: {
      name: {
        en: "English",
        fi: "English",
        pl: "English",
        et: "English",
        sv: "English",
        lv: "English",
        lt: "English",
        de: "English",
        es: "English",
        da: "English",
        no: "English",
        fr: "English",
        pt: "English",
        zh: "English"
      },
      short_name: {
        en: "EN",
        fi: "EN",
        pl: "EN",
        et: "EN",
        sv: "EN",
        lv: "EN",
        lt: "EN",
        de: "EN",
        es: "EN",
        da: "EN",
        no: "EN",
        fr: "EN",
        pt: "EN",
        zh: "EN"
      }
    },
    fi: {
      name: {
        en: "Suomi",
        fi: "Suomi",
        pl: "Suomi",
        et: "Suomi",
        sv: "Suomi",
        lv: "Suomi",
        lt: "Suomi",
        de: "Suomi",
        es: "Suomi",
        da: "Suomi",
        no: "Suomi",
        fr: "Suomi",
        pt: "Suomi",
        zh: "Suomi"
      },
      short_name: {
        en: "FI",
        fi: "FI",
        pl: "FI",
        et: "FI",
        sv: "FI",
        lv: "FI",
        lt: "FI",
        de: "FI",
        es: "FI",
        da: "FI",
        no: "FI",
        fr: "FI",
        pt: "FI",
        zh: "FI"
      }
    },
    sv: {
      name: {
        en: "Svenska",
        fi: "Svenska",
        pl: "Svenska",
        et: "Svenska",
        sv: "Svenska",
        lv: "Svenska",
        lt: "Svenska",
        de: "Svenska",
        es: "Svenska",
        da: "Svenska",
        no: "Svenska",
        fr: "Svenska",
        pt: "Svenska",
        zh: "Svenska"
      },
      short_name: {
        en: "SV",
        fi: "SV",
        pl: "SV",
        et: "SV",
        sv: "SV",
        lv: "SV",
        lt: "SV",
        de: "SV",
        es: "SV",
        da: "SV",
        no: "SV",
        fr: "SV",
        pt: "SV",
        zh: "SV"
      }
    },
    pl: {
      name: {
        en: "Polski",
        fi: "Polski",
        pl: "Polski",
        et: "Polski",
        sv: "Polski",
        lv: "Polski",
        lt: "Polski",
        de: "Polski",
        es: "Polski",
        da: "Polski",
        no: "Polski",
        fr: "Polski",
        pt: "Polski",
        zh: "Polski"
      },
      short_name: {
        en: "PL",
        fi: "PL",
        pl: "PL",
        et: "PL",
        sv: "PL",
        lv: "PL",
        lt: "PL",
        de: "PL",
        es: "PL",
        da: "PL",
        no: "PL",
        fr: "PL",
        pt: "PL",
        zh: "PL"
      }
    },
    ru: {
      name: {
        en: "Русский",
        fi: "Русский",
        pl: "Русский",
        et: "Русский",
        sv: "Русский",
        lv: "Русский",
        lt: "Русский",
        de: "Русский",
        es: "Русский",
        da: "Русский",
        no: "Русский",
        fr: "Русский",
        pt: "Русский",
        zh: "Русский"
      },
      short_name: {
        en: "RU",
        fi: "RU",
        pl: "RU",
        et: "RU",
        sv: "RU",
        lv: "RU",
        lt: "RU",
        de: "RU",
        es: "RU",
        da: "RU",
        no: "RU",
        fr: "RU",
        pt: "RU",
        zh: "RU"
      }
    },
    et: {
      name: {
        en: "Eesti",
        fi: "Eesti",
        pl: "Eesti",
        et: "Eesti",
        sv: "Eesti",
        lv: "Eesti",
        lt: "Eesti",
        de: "Eesti",
        es: "Eesti",
        da: "Eesti",
        no: "Eesti",
        fr: "Eesti",
        pt: "Eesti",
        zh: "Eesti"
      },
      short_name: {
        en: "ET",
        fi: "ET",
        pl: "ET",
        et: "ET",
        sv: "ET",
        lv: "ET",
        lt: "ET",
        de: "ET",
        es: "ET",
        da: "ET",
        no: "ET",
        fr: "ET",
        pt: "ET",
        zh: "ET"
      }
    },
    de: {
      name: {
        en: "Deutsch",
        fi: "Deutsch",
        pl: "Deutsch",
        et: "Deutsch",
        sv: "Deutsch",
        lv: "Deutsch",
        lt: "Deutsch",
        de: "Deutsch",
        es: "Deutsch",
        da: "Deutsch",
        no: "Deutsch",
        fr: "Deutsch",
        pt: "Deutsch",
        zh: "Deutsch"
      },
      short_name: {
        en: "DE",
        fi: "DE",
        pl: "DE",
        et: "DE",
        sv: "DE",
        lv: "DE",
        lt: "DE",
        de: "DE",
        es: "DE",
        da: "DE",
        no: "DE",
        fr: "DE",
        pt: "DE",
        zh: "DE"
      }
    },
    no: {
      name: {
        en: "Norsk",
        fi: "Norsk",
        pl: "Norsk",
        et: "Norsk",
        sv: "Norsk",
        lv: "Norsk",
        lt: "Norsk",
        de: "Norsk",
        es: "Norsk",
        da: "Norsk",
        no: "Norsk",
        fr: "Norsk",
        pt: "Norsk",
        zh: "Norsk"
      },
      short_name: {
        en: "NO",
        fi: "NO",
        pl: "NO",
        et: "NO",
        sv: "NO",
        lv: "NO",
        lt: "NO",
        de: "NO",
        es: "NO",
        da: "NO",
        no: "NO",
        fr: "NO",
        pt: "NO",
        zh: "NO"
      }
    },
    da: {
      name: {
        en: "Dansk",
        fi: "Dansk",
        pl: "Dansk",
        et: "Dansk",
        sv: "Dansk",
        lv: "Dansk",
        lt: "Dansk",
        de: "Dansk",
        es: "Dansk",
        da: "Dansk",
        no: "Dansk",
        fr: "Dansk",
        pt: "Dansk",
        zh: "Dansk"
      },
      short_name: {
        en: "DA",
        fi: "DA",
        pl: "DA",
        et: "DA",
        sv: "DA",
        lv: "DA",
        lt: "DA",
        de: "DA",
        es: "DA",
        da: "DA",
        no: "DA",
        fr: "DA",
        pt: "DA",
        zh: "DA"
      }
    },
    es: {
      name: {
        en: "Español",
        fi: "Español",
        pl: "Español",
        et: "Español",
        sv: "Español",
        lv: "Español",
        lt: "Español",
        de: "Español",
        es: "Español",
        da: "Español",
        no: "Español",
        fr: "Español",
        pt: "Español",
        zh: "Español"
      },
      short_name: {
        en: "ES",
        fi: "ES",
        pl: "ES",
        et: "ES",
        sv: "ES",
        lv: "ES",
        lt: "ES",
        de: "ES",
        es: "ES",
        da: "ES",
        no: "ES",
        fr: "ES",
        pt: "ES",
        zh: "ES"
      }
    },
    fr: {
      name: {
        en: "Français",
        fi: "Français",
        pl: "Français",
        et: "Français",
        sv: "Français",
        lv: "Français",
        lt: "Français",
        de: "Français",
        es: "Français",
        da: "Français",
        no: "Français",
        fr: "Français",
        pt: "Français",
        zh: "Français"
      },
      short_name: {
        en: "FR",
        fi: "FR",
        pl: "FR",
        et: "FR",
        sv: "FR",
        lv: "FR",
        lt: "FR",
        de: "FR",
        es: "FR",
        da: "FR",
        no: "FR",
        fr: "FR",
        pt: "FR",
        zh: "FR"
      }
    },
    pt: {
      name: {
        en: "Português",
        fi: "Português",
        pl: "Português",
        et: "Português",
        sv: "Português",
        lv: "Português",
        lt: "Português",
        de: "Português",
        es: "Português",
        da: "Português",
        no: "Português",
        fr: "Português",
        pt: "Português",
        zh: "Português"
      },
      short_name: {
        en: "PTT",
        fi: "PT",
        pl: "PT",
        et: "PT",
        sv: "PT",
        lv: "PT",
        lt: "PT",
        de: "PT",
        es: "PT",
        da: "PT",
        no: "PT",
        fr: "PT",
        pt: "PT",
        zh: "P"
      }
    },
    zh: {
      name: {
        en: "中文",
        fi: "中文",
        pl: "中文",
        et: "中文",
        sv: "中文",
        lv: "中文",
        lt: "中文",
        de: "中文",
        es: "中文",
        da: "中文",
        no: "中文",
        fr: "中文",
        pt: "中文",
        zh: "中文"
      },
      short_name: {
        en: "ZH",
        fi: "ZH",
        pl: "ZH",
        et: "ZH",
        sv: "ZH",
        lv: "ZH",
        lt: "ZH",
        de: "ZH",
        es: "ZH",
        da: "ZH",
        no: "ZH",
        fr: "ZH",
        pt: "ZH",
        zh: "ZH"
      }
    }
  },
  core: {
    components: {
      settings: {
        SettingsSelector: {
          logout: {
            en: "Log out",
            fi: "Kirjaudu ulos",
            ru: "Выход",
            pl: "Wyloguj się",
            et: "Logi välja",
            sv: "Logga ut",
            lv: "Izrakstīties",
            lt: "Atsijungti",
            de: "Abmelden",
            es: "Cerrar sesión",
            da: "Log ud",
            no: "Logg ut",
            fr: "Déconnexion",
            pt: "Terminar sessão",
            zh: "登出"
          }
        }
      },
      ui: {
        form: {
          ok: {
            en: "Ok",
            fi: "Ok",
            ru: "ОК",
            pl: "Ok",
            et: "OK",
            sv: "Ok",
            lv: "Ok",
            lt: "Ok",
            es: "Ok",
            da: "Ok",
            de: "Ok",
            no: "Ok",
            fr: "OK",
            pt: "Ok",
            zh: "确定"
          },
          cancel: {
            en: "Cancel",
            fi: "Peruuta",
            ru: "Отмена",
            pl: "Anuluj",
            et: "Tühista",
            sv: "Avbryt",
            lv: "Atcelt",
            lt: "Atšaukti",
            de: "Abbrechen",
            es: "Cancelar",
            da: "Annuller",
            no: "Avbryt",
            fr: "Annuler",
            pt: "Cancelar",
            zh: "取消"
          },
          add: {
            en: "Add",
            fi: "Lisää",
            ru: "Добавить",
            pl: "Więcej",
            et: "Lisa",
            sv: "Lägg till",
            lv: "Pievienot",
            lt: "Pridėti",
            de: "Hinzufügen",
            es: "Añadir",
            da: "Tilføj",
            no: "Legg til",
            fr: "Ajouter",
            pt: "Adicionar",
            zh: "添加"
          },
          edit: {
            en: "Edit",
            fi: "Muokkaa",
            ru: "Изменить",
            pl: "Edytuj",
            et: "Muuda",
            sv: "Redigera",
            lv: "Rediģēt",
            lt: "Redaguoti",
            de: "Bearbeiten",
            es: "Editar",
            da: "Rediger",
            no: "Rediger",
            fr: "Modifier",
            pt: "Editar",
            zh: "编辑"
          },
          update: {
            en: "Update",
            fi: "Päivitä",
            ru: "Обновить",
            pl: "Odśwież",
            et: "Uuenda",
            sv: "Uppdatera",
            lv: "Atjaunināt",
            lt: "Atnaujinti",
            de: "Aktualisieren",
            es: "Actualizar",
            da: "Opdater",
            no: "Oppdater",
            fr: "Mettre à jour",
            pt: "Atualizar",
            zh: "更新"
          },
          remove: {
            en: "Remove",
            fi: "Poista",
            ru: "Удалить",
            pl: "Usuń",
            et: "Kustuta",
            sv: "Ta bort",
            lv: "Dzēst",
            lt: "Pašalinti",
            de: "Entfernen",
            es: "Eliminar",
            da: "Fjern",
            no: "Fjern",
            fr: "Supprimer",
            pt: "Remover",
            zh: "删除"
          },
          start: {
            en: "Start",
            fi: "Aloita",
            ru: "Начать",
            pl: "Rozpocznij",
            et: "Alusta",
            sv: "Börja",
            lv: "Sākt",
            lt: "Pradėti",
            de: "Starten",
            es: "Comenzar",
            da: "Start",
            no: "Start",
            fr: "Démarrer",
            pt: "Começar",
            zh: "开始"
          },
          next: {
            en: "Next",
            fi: "Seuraava",
            ru: "Далее",
            pl: "Dalej",
            et: "Järgmine",
            sv: "Nästa",
            lv: "Nākošais",
            lt: "Kitas",
            de: "Weiter",
            no: "Neste",
            da: "Næste",
            es: "Siguiente",
            fr: "Suivant",
            pt: "Seguinte",
            zh: "下一步"
          },
          previous: {
            en: "Previous",
            fi: "Edellinen",
            ru: "Назад",
            pl: "Wstecz",
            et: "Eelmine",
            sv: "Föregående",
            lv: "Iepriekšējais",
            lt: "Ankstesnis",
            de: "Zurück",
            no: "Forrige",
            da: "Forrige",
            es: "Anterior",
            fr: "Précédent",
            pt: "Anterior",
            zh: "上一步"
          },
          close: {
            en: "Close",
            fi: "Sulje",
            ru: "Закрыть",
            pl: "Zamknij",
            et: "Sulge",
            sv: "Stäng",
            lv: "Aizvērt",
            lt: "Uždaryti",
            de: "Schließen",
            es: "Cerrar",
            da: "Luk",
            no: "Lukk",
            fr: "Fermer",
            pt: "Fechar",
            zh: "关闭"
          },
          back: {
            en: "Back",
            fi: "Palaa",
            ru: "Назад",
            pl: "Wstecz",
            et: "Tagasi",
            sv: "Tillbaka",
            lv: "Atgriezties",
            lt: "Atgal",
            de: "Zurück",
            es: "Atrás",
            da: "Tilbage",
            no: "Tilbake",
            fr: "Retour",
            pt: "Anterior",
            zh: "返回"
          },
          return: {
            en: "Return",
            fi: "Palaa",
            ru: "Вернуться",
            pl: "Wróć",
            et: "Tagasi",
            sv: "Tillbaka",
            lv: "Atgriezties",
            lt: "Grįžti",
            de: "Zurück",
            es: "Volver",
            da: "Tilbage",
            no: "Gå tilbake",
            fr: "Retour",
            pt: "Voltar",
            zh: "返回"
          },
          continue: {
            en: "Continue",
            fi: "Jatka",
            ru: "Продолжить",
            pl: "Kontynuuj",
            et: "Jätka",
            sv: "Fortsätt",
            lv: "Turpināt",
            lt: "Tęsti",
            de: "Weiter",
            es: "Continuar",
            da: "Fortsæt",
            no: "Fortsett",
            fr: "Continuer",
            pt: "Continuar",
            zh: "继续"
          },
          show: {
            en: "Show",
            fi: "Näytä",
            ru: "Показать",
            pl: "Pokaż",
            et: "Näita",
            sv: "Visa",
            lv: "Rādīt",
            lt: "Rodyti",
            de: "Anzeigen",
            es: "Mostrar",
            da: "Vis",
            no: "Vis",
            fr: "Afficher",
            pt: "Mostrar",
            zh: "显示/展开"
          },
          hide: {
            en: "Hide",
            fi: "Piilota",
            ru: "Скрыть",
            pl: "Ukryj",
            et: "Peida",
            sv: "Dölj",
            lv: "Paslēpt",
            lt: "Paslėpti",
            de: "Ausblenden",
            es: "Ocultar",
            da: "Skjul",
            no: "Skjul",
            fr: "Masquer",
            pt: "Ocultar",
            zh: "隐藏/收起"
          },
          save: {
            en: "Save",
            fi: "Tallenna",
            ru: "Сохранить",
            pl: "Zapisz",
            et: "Salvesta",
            sv: "Spara",
            lv: "Saglabāt",
            lt: "Išsaugoti",
            de: "Speichern",
            es: "Guardar",
            da: "Gem",
            no: "Lagre",
            fr: "Enregistrer",
            pt: "Guardar",
            zh: "保存"
          },
          preview: {
            en: "Preview",
            fi: "Esikatsele",
            ru: "Предпросмотр",
            pl: "Podgląd",
            et: "Eelvaade",
            sv: "Förhandsgranska",
            lv: "Priekšskatīt",
            lt: "Peržiūrėti",
            de: "Voransicht",
            es: "Vista previa",
            da: "Forhåndsvisning",
            no: "Forhåndsvis",
            fr: "Aperçu",
            pt: "Pré-visualizar",
            zh: "预览"
          },
          login: {
            en: "Login",
            fi: "Kirjaudu sisään",
            ru: "Вход",
            pl: "Zaloguj się",
            et: "Logi sisse",
            sv: "Logga in",
            lv: "Pierakstīties",
            lt: "Prisijungti",
            de: "Anmelden",
            no: "Logg Inn",
            da: "Log på",
            es: "Iniciar sesión",
            fr: "Connexion",
            pt: "Iniciar sessão",
            zh: "登录"
          },
          logout: {
            en: "Log out",
            fi: "Kirjaudu ulos",
            ru: "Выход",
            pl: "Wyloguj się",
            et: "Logi välja",
            sv: "Logga ut",
            lv: "Izrakstīties",
            lt: "Atsijungti",
            de: "Abmelden",
            no: "Logg ut",
            da: "Log ud",
            es: "Cerrar sesión",
            fr: "Déconnexion",
            pt: "Terminar sessão",
            zh: "登出"
          }
        }
      }
    }
  },
  errors: {
    email_not_found: {
      en: "Email not found",
      fi: "Sähköpostia ei löydy",
      ru: "Электронная почта не найдена",
      pl: "Nie znaleziono adresu e-mail",
      et: "E-posti ei leitud",
      sv: "E-postadressen hittades inte",
      lv: "E-pasts nav atrasts",
      lt: "Elektroninio pašto nerasta",
      de: "E-Mail nicht gefunden",
      da: "E-mail ikke fundet",
      no: "E-postadressen ble ikke funnet",
      fr: "Adresse e-mail non trouvée",
      pt: "E-mail não encontrado",
      zh: "电子邮件地址未找到"
    }
  }
}
