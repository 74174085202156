import { ModuleManager } from "./ModuleManager"

export interface App {
  moduleManager: ModuleManager
  name: string
  rootComponent: any
  forceUpdate(): void
}

export class AppImpl implements App {
  static theApp: App

  name = "App"
  rootComponent: any

  constructor(readonly moduleManager = new ModuleManager()) {
    AppImpl.theApp = this
  }

  forceUpdate() {
    this.rootComponent?.forceUpdate()
  }
}
