import type { CoreActions } from "../modules/actions/CoreActions"
import { type Logger, logger } from "../modules/logging/Logger"
import type { Doc, Link } from "../modules/state/model/Model"

export interface Module {
  /**
   * The name of this module as an array, including namespace tree.
   */
  moduleName?: string
  componentName?: string[]
}

export interface ModuleInternal extends Module {
  /**
   * Module priority. Higher priority modules will be set up first
   */
  setupPriority: number

  /**
   * Module priority when resolving dependencies. Highest priority module will be set as defualt
   * and rest will be ordered in modules collection according to their priority
   */
  dependencyPriority: number

  /**
   * If set to true the create method of this module will be called for each dependency. If set to false the same module instance
   * will be returned to each dependency
   */
  isFactory: boolean

  /**
   * Retuns the names of other modules this module depends on, without the "Module" postfix, for example "Cat" for "CatModule"
   */
  dependencies: string[]

  /**
   * Retuns the names of other modules this module depends on. These are set for all modules.
   */
  defaultDependencies: string[]

  /**
   * Called for factory type methods. Should return an instance of module type.
   * @param target The module for which this dependency will be created
   */
  createInstance(target: Module): Module

  /**
   * Called when module is set up. Setup order of modules depends on setupPriority value.
   */
  setup(): void
}

export abstract class BaseModule implements ModuleInternal {
  declare coreActions: CoreActions

  private _logger: Logger | undefined

  get logger(): Logger {
    return (this._logger ??= new logger(this.constructor.name))
  }

  get setupPriority(): number {
    return 0
  }

  get dependencyPriority(): number {
    return 0
  }

  get isFactory(): boolean {
    return false
  }

  /**
   * Name of the app this module belongs to. Not necessarily the running app.
   */
  abstract get appName(): string

  createInstance(target: Module): Module {
    throw new Error("Not implemented")
  }

  // tslint:disable-next-line:no-empty
  setup(): void {}

  get dependencies(): string[] {
    return []
  }

  get defaultDependencies() {
    return ["CoreActions"]
  }

  abstract get moduleName(): string

  protected doc<T>(link: Link<T>): T | undefined
  protected doc<T>(id: string, type: string): T | undefined
  protected doc<T>(idOrLink: string | Link<T>, type?: string): T | undefined {
    if (!idOrLink) return undefined

    if (typeof idOrLink === "string" || typeof idOrLink === "number") {
      return this.coreActions.getDocumentLocal<T>(idOrLink, type)
    }

    return this.coreActions.getDocumentLocal<T>(idOrLink.id, idOrLink.__type)
  }

  protected docAll<T>(type: string) {
    return this.coreActions.getAllDocumentsLocal<T>(type)
  }

  protected docDefault<T>(type: string) {
    return this.coreActions.getDefaultDocumentLocal<T>(type)
  }

  protected view(name: string) {
    return this.coreActions.getViewLocal(name)
  }

  protected set(document: Doc) {
    this.coreActions.setDocumentLocal(document)
  }
}

export abstract class BaseModuleWithAppName extends BaseModule {
  get appName() {
    return "core"
  }
}
