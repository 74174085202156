import { CoreApi, RestApiCallParameters } from "core/modules/api/CoreApi"
import { ProgressManager } from "core/modules/progress/ProgressManager"
import { ConversionMap } from "core/modules/state/conversionmap/ConversionMap"
import { DocumentValueConverter } from "core/modules/state/conversionmap/ValueConverters"
import { DocumentConverter } from "core/modules/state/documentconverter/DocumentConverter"
import { Doc, HttpError, defaultDocumentId } from "core/modules/state/model/Model"
import { ModelManager } from "core/modules/state/model/ModelManager"
import { ViewContainerManager } from "core/modules/state/model/ViewContainerManager"
import { Store } from "core/modules/store/Store"

import { BaseModuleWithAppName } from "../../controller/Module"

export interface ViewParameters extends Partial<RestApiCallParameters> {
  itemsKey?: string // Where to look for items array when importing the view
}

export interface CoreActions {
  // Event hanlders

  createDocumentBefore: ((parameters: RestApiCallParameters) => boolean)[] // TBD
  createDocumentAfter: ((document: Doc, parameters) => void)[]

  updateDocumentBefore: () => void[] // TBD
  updateDocumentAfter: ((document: Doc, previousDocuemnt: Doc, parameters) => void)[] // TBD

  removeDocumentBefore: () => void[] // TBD
  removeDocumentAfter: ((document: Doc, parameters) => void)[]

  getDocumentBefore: () => void[] // TBD
  getDocumentDuring: ((document: Doc, parameters) => Doc)[]
  getDocumentAfter: ((document: Doc, parameters) => void)[]

  getViewBefore: () => void[] // TBD
  getViewAfter: () => void[] // TBD

  /**
   * Get document from local store.
   */
  getDocumentLocal<T extends Doc>(id?: string, type?: string): T

  /**
   * Get document from local store.
   */
  getAllDocumentsLocal<T extends Doc>(type: string): T[]

  /**
   * Set a document locally.
   */
  setDocumentLocal(doc: Doc)

  /**
   * Set a document locally.
   */
  setDocumentsLocal(doc: Doc[])

  /**
   * Get default document from model or from API.
   */
  getDefaultDocumentLocal<T extends Doc>(type: string): T

  /**
   * Set the default document using the API or locally.
   */
  setDefaultDocumentLocal<T extends Doc>(doc: T)

  /**
   * Remove a document using the API.
   */
  removeDocumentLocal(doc: Doc): void

  /**
   * Returns local documents in a given view
   */
  getViewLocal<T extends Doc>(viewName: string): ViewContainerManager<T>

  getDocument<T extends Doc>(api: CoreApi, parameters: any): Promise<T>

  getView<T extends Doc>(api: CoreApi, parameters: ViewParameters): Promise<ViewContainerManager<T>>

  invalidateView(viewName, invalidationType: "unload" | "invalidateWhenUsed")

  invalidateAllViews(invalidationType: "unload" | "invalidateWhenUsed")

  createDocument<T extends Doc>(api: CoreApi, parameters: RestApiCallParameters): Promise<T>

  updateDocument(api: CoreApi, document: Doc, parameters: RestApiCallParameters): Promise<Response>

  removeDocument(api: CoreApi, parameters: RestApiCallParameters): Promise<Response>

  importDocument<T>(documentRaw: any, documentType: string): Promise<T>
}

export class CoreActionsModule extends BaseModuleWithAppName implements CoreActions {
  createDocumentBefore = [] as ((parameters: RestApiCallParameters) => boolean)[]
  createDocumentAfter = [] as ((document: Doc, parameters) => void)[]
  updateDocumentBefore
  updateDocumentAfter = [] as ((document: Doc, previousDocument: Doc, parameters) => void)[]
  removeDocumentBefore
  removeDocumentAfter = [] as ((document: Doc, parameters) => void)[]
  getDocumentBefore
  getDocumentDuring = [] as ((document: Doc, parameters) => Doc)[]
  getDocumentAfter = [] as ((document: Doc, parameters) => void)[]
  getViewBefore
  getViewAfter

  declare conversionMap: ConversionMap
  declare documentConverter: DocumentConverter
  declare modelManager: ModelManager
  declare progressManager: ProgressManager
  declare store: Store

  private ongoingGetViews: string[]

  constructor() {
    super()
    this.ongoingGetViews = []
  }

  get moduleName() {
    return "CoreActions"
  }
  get dependencies() {
    return ["Store", "ModelManager", "ProgressManager", "ConversionMap", "DocumentConverter"]
  }

  getDocument<T extends Doc>(api: CoreApi, parameters: any): Promise<T> {
    this.logger.debug("Get document", parameters)

    if (!parameters.type) this.logger.errorAndThrow("Attempted to get document without type", parameters)

    return new Promise((resolve, reject) => {
      this.store.dispatch(dispatch => {
        const progress = this.progressManager.startProgress({
          name: "getDocument",
          scope: `${parameters.type}_${parameters.id}`
        })

        parameters.apiCallType = parameters.apiCallType || "get"
        return api
          .restApiCall(parameters)
          .then(response => {
            if (parameters.rawData) {
              response.json().then(json => {
                resolve(json)
              })
              return
            }

            if (parameters.skipLocalUpdates) {
              resolve(undefined)
              return
            }
            if (response.ok) {
              response
                .json()
                .then(json => {
                  dispatch({
                    type: "ReceiveDocument",
                    payload: json,
                    parameters,
                    onReceiveAfter: this.getDocumentDuring
                  } as ReceiveDocumentAction)

                  for (const handler of this.getDocumentAfter) {
                    const doc = handler(this.modelManager.getDocument<T>(json.id, parameters.type), parameters)
                  }

                  resolve(this.modelManager.getDocument<T>(json.id, parameters.type))
                })
                .catch(e => {
                  reject(e)
                })
            } else {
              if (api.errorStatusOnly || parameters.errorStatusOnly) {
                const error = this.getHttpError(response.status, {})
                this.progressManager.failProgress(progress, error)
                reject(error)
              } else {
                response
                  .json()
                  .then(json => {
                    const error = this.getHttpError(response.status, json)
                    this.progressManager.failProgress(progress, error)
                    reject(error)
                  })
                  .catch(e => {
                    // this will just throw the error as an uncaught error so if the interface
                    // is showing the spinner while something is loading, the app will appear to lock up
                    // throw e

                    // so let's make an error object and return that so we can at least recover
                    // from the error and keep our progressManager informed of what's going on
                    let error_status_value = 500
                    if (response?.status) {
                      error_status_value = response.status
                    }
                    const error = this.getHttpError(error_status_value, { error: e.name, errorDetails: e.message })
                    this.progressManager.failProgress(progress, error)
                    reject(error)
                  })
              }
            }
          })
          .catch(e => {
            reject(e)
          })
      })
    })
  }

  createDocument<T extends Doc>(api: CoreApi, parameters: RestApiCallParameters): Promise<T> {
    parameters = parameters || {}
    if (!parameters.body) this.logger.errorAndThrow("Attempted to create document without body parameter", parameters)

    const documentType = parameters.type || parameters.body.__type

    if (!documentType) this.logger.errorAndThrow("Attempted to create document without document type", parameters)

    parameters.body = this.documentConverter.exportDocument(parameters.body, documentType)

    if (!documentType) this.logger.errorAndThrow("Attempted to create document without type", parameters)

    parameters.type = documentType

    return new Promise((resolve, reject) => {
      this.store.dispatch(dispatch => {
        parameters.apiCallType = parameters.apiCallType || "create"
        const progress = this.progressManager.startProgress({
          name: "CreateDocument",
          scope: documentType
        })

        for (const handler of this.createDocumentBefore) {
          if (!handler(parameters)) {
            this.logger.info("Create document call canceled by event handler")
          }
        }

        return api.restApiCall(parameters).then(response => {
          if (response.ok) {
            if (parameters.skipLocalUpdates) {
              resolve(undefined)
              return
            }

            response.json().then(json => {
              dispatch({
                type: "ReceiveDocument",
                payload: json,
                parameters
              })
              this.progressManager.completeProgress(progress)

              for (const handler of this.createDocumentAfter) {
                handler(this.getDocumentLocal(json.id, parameters.type), parameters)
              }

              this.invalidateViewsIfNeeded(api, parameters.apiCallType, documentType)
              resolve(this.getDocumentLocal<T>(json.id, parameters.type))
            })
          } else {
            if (api.errorStatusOnly || parameters.errorStatusOnly) {
              const error = this.getHttpError(response.status, {})
              this.progressManager.failProgress(progress, error)
              reject(error)
            } else {
              response
                .json()
                .then(json => {
                  const error = this.getHttpError(response.status, json)
                  this.progressManager.failProgress(progress, error)
                  reject(error)
                })
                .catch(e => {
                  throw e
                })
            }
          }
        })
      })
    })
  }

  updateDocument<T>(api: CoreApi, document: Doc, parameters: RestApiCallParameters): Promise<T> {
    parameters = parameters || {}
    this.logger.debug("Update document", { document, parameters })

    parameters.body = parameters.body || document

    if (!parameters.body) this.logger.errorAndThrow("Attempted to update document but no document provided")

    parameters.type = parameters.type || parameters.body.__type

    parameters.id = parameters.id || document.id

    if (!parameters.id) this.logger.errorAndThrow("Attempted to update document without id", parameters)

    if (!parameters.type) this.logger.errorAndThrow("Attempted to update document without type", parameters)

    return new Promise((resolve, reject) => {
      this.store.dispatch(dispatch => {
        parameters.apiCallType = parameters.apiCallType || "update"
        const progress = this.progressManager.startProgress({
          name: "UpdateDocument",
          scope: `${parameters.type}_${document.id}`
        })
        return api.restApiCall(parameters).then(response => {
          if (response.ok) {
            if (parameters.skipLocalUpdates) {
              resolve(undefined)
              return
            }

            response.json().then(json => {
              dispatch({
                type: "ReceiveDocument",
                payload: json,
                parameters
              })
              this.progressManager.completeProgress(progress)
              this.invalidateViewsIfNeeded(api, parameters.apiCallType, parameters.type)

              const localDocument = this.getDocumentLocal<T>(json.id, parameters.type)
              for (const handler of this.updateDocumentAfter) {
                handler(localDocument, document, parameters)
              }

              resolve(localDocument)
            })
          } else {
            reject(response)
          }
        })
      })
    })
  }

  removeDocument(api: CoreApi, parameters: RestApiCallParameters): Promise<Response> {
    if (!parameters.id) this.logger.errorAndThrow("Attempted to remove document without id parameter", parameters)

    if (!parameters.type) this.logger.errorAndThrow("Attempted to remove document without type parameter", parameters)

    return new Promise((resolve, reject) => {
      parameters.apiCallType = "remove"
      const progress = this.progressManager.startProgress({
        name: "RemoveDocument",
        scope: `${parameters.type}_${parameters.__id}`
      })
      return api.restApiCall(parameters).then(response => {
        if (response.ok) {
          if (parameters.skipLocalUpdates) {
            resolve(undefined)
            return
          }

          const localDoc = this.getDocumentLocal(parameters.id, parameters.type)
          if (localDoc) this.removeDocumentLocal(localDoc)

          this.progressManager.completeProgress(progress)

          for (const handler of this.removeDocumentAfter) {
            handler(localDoc, parameters)
          }

          this.invalidateViewsIfNeeded(api, parameters.apiCallType, parameters.type)
          resolve(response)
        } else {
          reject(response)
        }
      })
    })
  }

  getDocumentLocal<T extends Doc>(id: string, type: string): T {
    return this.modelManager.getDocument<T>(id, type)
  }

  getAllDocumentsLocal<T extends Doc>(type: string): T[] {
    return this.modelManager.getDocuments(type) as T[]
  }

  setDocumentLocal(doc: Doc) {
    this.store.dispatch(dispatch => dispatch({ type: "SetDocumentLocal", document: doc }))
  }

  setDocumentsLocal(documents: Doc[]) {
    this.store.dispatch(dispatch => {
      dispatch({ type: "SetDocumentsLocal", documents })
    })
  }

  getDefaultDocumentLocal<T extends Doc>(type: string): T {
    return this.modelManager.getDefaultDocument<T>(type)
  }

  setDefaultDocumentLocal<T extends Doc>(doc: T) {
    this.logger.debug("Set default document local", doc)

    if (doc.id !== defaultDocumentId) (doc as T & { __originalId: T["id"] }).__originalId = doc.id

    doc.id = defaultDocumentId

    this.store.dispatch(dispatch => {
      dispatch({ type: "SetDocumentLocal", document: doc })
    })
  }

  removeDocumentLocal(doc: Doc) {
    this.logger.debug("Remove document", doc)
    this.store.dispatch(dispatch => {
      dispatch({ type: "RemoveDocumentLocal", payload: doc })
    })

    this.deleteCascade(doc)
  }

  getViewKey(parameters: ViewParameters) {
    return JSON.stringify(parameters)
  }

  clearViewFromOngoing(viewKey) {
    const index = this.ongoingGetViews.indexOf(viewKey)
    if (index > -1) {
      this.ongoingGetViews.splice(index, 1)
    }
  }

  getViewLocal<T extends Doc>(viewName: string) {
    const view = this.modelManager.getView<T>(viewName)

    if (view && !view.valid) {
      this.logger.info("View was marked as invalid. Refresing it", { viewName, parameters: view.parameters })
      // Don't get views that are already on progress
      const viewKey = this.getViewKey(view.parameters)
      if (this.ongoingGetViews.indexOf(viewKey) == -1) {
        this.getView<T>(view.api, view.parameters)
      }
    }

    return view
  }

  getView<T extends Doc>(api: CoreApi, parameters: ViewParameters): Promise<ViewContainerManager<T>> {
    const viewKey = this.getViewKey(parameters)
    this.ongoingGetViews.push(viewKey)
    this.logger.debug("Get view", parameters)
    let fetchAll = false
    // Check if we are fetching all documents
    const conversionMapItem = this.conversionMap.map[parameters.type]
    if (conversionMapItem.getAll === true) {
      fetchAll = true
    }
    return new Promise((resolve, reject) => {
      this.store.dispatch(dispatch => {
        parameters.apiCallType = "get"
        const progress = this.progressManager.startProgress({
          name: "RequestView",
          scope: parameters.type
        })
        return api.restApiCall(parameters).then(response => {
          if (response.ok) {
            this.progressManager.completeProgress(progress)

            if (response.status === 204) {
              // No content. Dispatch empty message to clear view
              dispatch({
                type: "ReceiveView",
                payload: undefined,
                parameters,
                raw: undefined,
                api
              })

              resolve(this.modelManager.getView<T>(parameters.type))
            } else {
              response.json().then(async json => {
                let items = json.items
                if (fetchAll) {
                  let page = 1
                  let currentTotal = items.length
                  const total = json.total
                  // If current page is not enough, fetch more and merge results
                  if (total > currentTotal) {
                    const apiDescription = conversionMapItem.__api
                    // Get the default per_page param for this collection
                    const perPageParam = apiDescription.get.search.filter(param => param.name == "per_page")
                    let perPage = 500
                    if (perPageParam && perPageParam[0] && perPageParam[0].default) {
                      perPage = perPageParam[0].default
                    }
                    // Get new results until we hit the end of the list
                    while (total > currentTotal) {
                      console.log(page)
                      console.log(currentTotal)
                      page++
                      const nextParameters = Object.assign({}, parameters)
                      nextParameters.page = page
                      nextParameters.per_page = perPage
                      const results = await api.restApiCall(nextParameters)
                      const nextJson = await results.json()
                      const nextItems = nextJson.items
                      items = items.concat(nextItems)
                      currentTotal += nextItems.length
                    }
                  }
                }

                dispatch({
                  type: "ReceiveView",
                  payload: parameters.itemsKey ? json[parameters.itemsKey] : items || json,
                  parameters,
                  raw: json,
                  api
                })

                this.clearViewFromOngoing(viewKey)

                resolve(this.modelManager.getView<T>(parameters.type))
              })
            }
          } else {
            response.json().then(json => {
              this.progressManager.failProgress(progress, json.getHttpError)
              reject(response)
            })
          }
        })
      })
    })
  }

  public invalidateView(viewName, invalidationType: "unload" | "invalidateWhenUsed") {
    this.logger.debug("Invalidating view", { viewName, invalidationType })
    this.store.dispatch(dispatch => {
      dispatch({
        type: "InvalidateView",
        viewName,
        invalidationType
      } as InvalidateViewAction)
    })
  }

  public invalidateAllViews(invalidationType: "unload" | "invalidateWhenUsed") {
    this.logger.debug("Invalidating all views", invalidationType)
    this.store.dispatch(dispatch => {
      dispatch({
        type: "InvalidateAllViews",
        invalidationType
      } as InvalidateAllViewsAction)
    })
  }

  /**
   * Import document from local data
   * @param documentRaw Raw JSON data of the document to import
   * @param documentType Type of the document to import
   * @param inline If set to true, any related documents will be lef
   * t inline in the original document.
   */
  async importDocument<T>(documentRaw: any, documentType: string) {
    if (!documentRaw) return

    return new Promise<T>(resolve => {
      this.store.dispatch(dispatch => {
        dispatch({
          type: "ReceiveDocument",
          payload: documentRaw,
          parameters: { type: documentType }
        })
      })

      resolve(this.getDocumentLocal<T>(documentRaw.id, documentType))
    })
  }

  protected invalidateViewsIfNeeded(api: CoreApi, apiCallType: string, documentType: string) {
    this.logger.debug("Updating related views after REST API operation", { apiCallType, documentType })
    for (const key of Object.keys(this.conversionMap.map)) {
      const collection = this.conversionMap.map[key]

      // Check if this view needs to be updated due to update
      if (
        collection &&
        collection.__api &&
        collection.__api.invalidateUnload &&
        collection.__api.invalidateUnload[documentType] &&
        collection.__api.invalidateUnload[documentType][apiCallType]
      ) {
        this.logger.debug("Unloading view because of another action", { key, apiCallType, documentType })
        this.invalidateView(key, "unload")
      } else if (
        collection &&
        collection.__api &&
        collection.__api.invalidateRefreshWhenUsed &&
        collection.__api.invalidateRefreshWhenUsed[documentType] &&
        collection.__api.invalidateRefreshWhenUsed[documentType][apiCallType]
      ) {
        this.logger.debug("Invalidating view because of another action. It will be refreshed when accessed next", {
          key,
          apiCallType,
          documentType
        })
        // Check if invalidation requires delay, with some actions the server might not have updated the index list right after update finishes
        // Delay comes from ES index
        if (collection.__api.invalidateRefreshWhenUsedDelay) {
          window.setTimeout(() => {
            this.invalidateView(key, "invalidateWhenUsed")
          }, collection.__api.invalidateRefreshWhenUsedDelay)
        } else {
          this.invalidateView(key, "invalidateWhenUsed")
        }
      }
    }
  }

  protected getHttpError(status: number, json: any): HttpError {
    return {
      error: json.error,
      httpStatusCode: status,
      error_description: json.errorDetails ?? json.error_description
    }
  }

  protected deleteCascade(doc: Doc) {
    if (this.conversionMap.map[doc.__type].__noDeleteCascade) return

    for (const docType of Object.keys(this.conversionMap.map)) {
      const conversionMapItem = this.conversionMap.map[docType]

      for (const linkName of Object.keys(conversionMapItem)) {
        const converter = conversionMapItem[linkName] as DocumentValueConverter
        if (converter.converterType === "LinkConverter" && converter.target === doc.__type) {
          // Found a single link. Delete all references
          for (const docToCheck of this.getAllDocumentsLocal(docType)) {
            if (!docToCheck) continue

            if (docToCheck[linkName] && docToCheck[linkName].id === doc.id) {
              this.logger.debug("Delete cascade, removing reference to deleted object from document", docToCheck)
              docToCheck[linkName] = undefined
              this.setDocumentLocal(docToCheck)
            }
          }
        }
        if (converter.converterType === "LinkArrayConverter" && converter.target === doc.__type) {
          // Found array link. Delete all references
          for (const docToCheck of this.getAllDocumentsLocal(docType)) {
            if (!docToCheck || !docToCheck[linkName]) continue

            let modified = false
            for (let i = docToCheck[linkName].length - 1; i >= 0; i--) {
              if (docToCheck[linkName][i].id === doc.id) {
                docToCheck[linkName].splice(i, 1)
                modified = true
              }
            }
            if (modified) {
              this.logger.debug(
                "Delete cascade, removing reference to deleted object from document's link array",
                docToCheck
              )
              this.setDocumentLocal(docToCheck)
            }
          }
        }
      }
    }
  }
}

// Actions

export interface Action {
  type: string
}

export interface RequestDoneAction extends Action {
  success: boolean
  error?: string
  errorCode?: string
}

export interface RequestViewAction extends Action {
  type: string
}

export interface ReceiveViewAction extends Action {
  type: string
  documentType: string
  payload: any
  itemsKey?: string
  totalKey?: string
  page?: number
  itemsPerPage?: number
  parameters: any
  raw: any
  api: CoreApi
}

export interface ReceiveDocumentAction extends Action {
  payload: any
  parameters: any
  inline: boolean
  onReceiveAfter: ((document: Doc, parameters) => Doc)[]
}

export interface InvalidateViewAction extends Action {
  type: "InvalidateView"
  viewName: string
  documentType: string
  invalidationType: "unload" | "invalidateWhenUsed"
}

export interface InvalidateAllViewsAction extends Action {
  type: "InvalidateAllViews"
  documentType: string
  invalidationType: "unload" | "invalidateWhenUsed"
}

export interface SetDocumentLocalAction extends Action {
  document: Doc
}

export interface SetDocumentsLocalAction extends Action {
  documents: Doc[]
}
