export const apiRoot: string = import.meta.env.VITE_API_URL

export interface ApiConfig {
  apiRoot: string
  apiRelativePath: string[]
  clientId: string
  clientSecret: string
}

export const baseApiConfig: ApiConfig = {
  apiRoot,
  apiRelativePath: [],
  clientId: import.meta.env.VITE_API_CLIENT_ID,
  clientSecret: import.meta.env.VITE_API_CLIENT_SECRET
}
