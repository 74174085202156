import classNames from "classnames"
import * as csx from "csx"
import { style } from "typestyle"

import { ViewComponentProps, ViewComponentState } from "core/components/base/ViewComponent"

import { ViewComponent } from "../base/ViewComponent"

export interface BaseInputProps<T = string> extends ViewComponentProps {
  id?: string
  className?: string
  label?: string
  value?: T
  defaultValue?: T
  labelClass?: string
}

export interface BaseInputState extends ViewComponentState {}

export default class BaseInput<
  Props extends BaseInputProps<unknown>,
  State extends BaseInputState
> extends ViewComponent<Props, State> {
  get componentName(): string[] {
    return ["ui", "form", "BaseInput"]
  }

  protected get inputClass() {
    return this.getInputClass(true)
  }

  protected get inputClassNoFocus() {
    return this.getInputClass(false)
  }

  protected get inputBaseClass() {
    return style({
      border: "none"
    })
  }

  protected get labelContainerClass() {
    return style({
      width: "100%",
      textTransform: "uppercase",
      fontSize: "0.9rem",
      marginLeft: "0.5rem",
      letterSpacing: "1.1px"
    })
  }

  protected renderLabel() {
    if (!this.props.label) return null

    return (
      <div className={classNames(this.labelContainerClass, this.props.labelClass)}>
        <label htmlFor={this.props.id}>{this.props.label}</label>
      </div>
    )
  }

  private getInputClass(setFocusColor: boolean) {
    return style({
      width: "100%",
      border: "3px",
      borderStyle: "solid",
      backgroundColor: this.theme.colors.inputBackgroundColor.toString(),
      borderRadius: "0.5rem",
      marginTop: "0.5rem",
      borderColor: this.theme.colors.primary.fade(0.5).toString(),
      boxShadow: "unset",
      transition: "border-color 0.2s ease",
      $nest: {
        "&:focus": {
          borderColor: setFocusColor ? csx.important(this.theme.colors.primary.toString()) : undefined,
          borderRadius: "0.5rem"
        }
      }
    })
  }
}
